import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";

import './LoggedInUser.scss';
import { withTranslation } from "react-i18next";

class LoggedInUser extends Component {

    confirmLogout = () => {
        const { t } = this.props;
        const confirmed = window.confirm(t('global.logout_confirmation'));
        if (confirmed) {
            this.props.UserStore.logout();
        }
    }

    render() {
        let {me} = this.props.UserStore;
        const { t } = this.props;
        if (!this.props.UserStore.isLogged()) {
            return (
                <Grid item xs={12} className={'logged-in-user ' + this.props.customClass}>
                    <div className='user-name'>{me.first_name} {me.last_name}</div>
                    <span className='user-action' onClick={() => {this.props.UserStore.forceLogin()}}>{t('global.login')}</span>
                </Grid>
            );
        }

        return (
            <Grid item xs={12} className={'logged-in-user ' + this.props.customClass}>
                <div className='user-name'>{me.first_name} {me.last_name}</div>
                <span className='user-action' onClick={this.confirmLogout}>{t('global.logout')}</span>
            </Grid>
        )
    }
}

LoggedInUser = inject('UserStore')(observer(LoggedInUser));
export default withTranslation()(LoggedInUser);
