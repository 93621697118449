export default function getMaintenanceApi() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    let host = '';

    if (hostname === 'localhost') {
        host = 'anytime-reservations.muuvlabs.local';
    } else {
        host = window.location.host;
    }
    return `${protocol}//${host}/maintenance/enabled`;

}