import React, { Component } from 'react';
import DateUtils from '../../Utils/DateUtils';
import moment from 'moment';

class TimePicker extends Component {
    state = {
        selectedHour: 0,
        selectedMinutes: 0,
        selectedPeriod: 'AM',
        availableMinutes: [],
        availableHours: []
    }

    // used to validate if the frame is available to select
    timeFrames = []
    // fraction of time in minutes, default is 15, so you will show 00, 15, 30, 45
    timeFraction = 15;

    hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    regularMinutes = [];
    lastHourMinutes = [];
    period = ['AM', 'PM'];

    constructor(props) {
        super(props);
        this.timeFraction = this.props.timeFraction ? this.props.timeFraction : this.timeFraction;
        // A day has 1440 minutes, it calculates the amount of timeframes
        this.timeFrames = Array(1440 / this.timeFraction).fill(false);
        let i = 0;
        this.regularMinutes = Array.apply(null, Array(60 / this.timeFraction)).map(() => {
            return i++ * this.timeFraction;
        })
        this.lastHourMinutes = [...this.regularMinutes, 59];
    }

    componentDidMount() {
        if (this.props.initialTime) {
            this.setInitialTime(this.props.initialTime);
            this.fillInvalidTimes();
            this.getValidHours();
        } else {
            this.setState({selectedHour: '0', selectedMinutes: '00', selectedPeriod: 'AM', availableMinutes: this.regularMinutes},
            () => {
                this.fillInvalidTimes();
                this.getValidHours();
            });
        }
    }


    /**
     * Check if the hour has available fractions of time
     */
    getValidHours() {
        let self = this;
        const { selectedPeriod } = self.state;
        const newHours = self.hours.filter((hour) => {
            let time = moment(`${hour}:00 ${selectedPeriod}`, ["h:mm A"]);
            const realposition = parseInt(time.format('HH')) * 60 / self.timeFraction;
            for (let i = realposition; i < (realposition + (60 / self.timeFraction)); i++) {
                if (!self.timeFrames[i]) {
                    return true;
                }
            }
            return false;
        });
        
        self.setState({availableHours: newHours, selectedHour: this.state.selectedHour ? this.state.selectedHour : newHours[0]}, this.validateLastHour);
    }

    fillInvalidTimes() {
        const { usedTimes, timezone } = this.props;
        if ( usedTimes ) {
            usedTimes.forEach( time => {
                const { start_int, stop_int } = time;
                this.setTimeframe(moment(DateUtils.displayTimeFromInt(start_int, timezone), ["h:mm A"]), moment(DateUtils.displayTimeFromInt(stop_int, timezone), ["h:mm A"]));
            });
        }
    }

    /**
     * add a time frame and check if overlaps
     * @param {*} openingTime
     * @param {*} closingTime
     * @param {*} timeframes
     */
    setTimeframe(openingTime, closingTime) {
        let i = (parseInt(openingTime.format("HH")) * (60 / this.timeFraction)) + (parseInt(openingTime.format("mm")) / this.timeFraction);
        let j = (parseInt(closingTime.format("HH")) * (60 / this.timeFraction)) + (parseInt(closingTime.format("mm")) / this.timeFraction);
        for (i; i < j; i++) {
            if (this.timeFrames[i]) {
                return false;
            } else {
                this.timeFrames[i] = true;
            }
        }
        return true;
    }


    setInitialTime(initialTime) {
        const [time, period] = initialTime.split(' ');
        const [hours, minutes] = time.split(':');
        this.setState({
            selectedHour: hours,
            selectedMinutes: minutes,
            selectedPeriod: period
        }, this.validateLastHour);
    }

    /**
     * check if the selected time is 11pm to show the 59 minute
     */
    validateLastHour = () => {
        if (this.state.selectedHour === '11' && this.state.selectedPeriod === 'PM') {
            this.setState({availableMinutes: this.lastHourMinutes.filter( minute => {
                return this.isValidTime(minute, this.state.selectedHour, this.state.selectedPeriod);
            })}, this.handleChange)
        } else {
            this.setState({availableMinutes: this.regularMinutes.filter( minute => {
                return this.isValidTime(minute, this.state.selectedHour, this.state.selectedPeriod);
            })}, this.handleChange);
        }
    }

    /**
     * when changing hours or periods set the first available time if the previously selected is not available
     */
    minutesSelectedOnList() {
        const hasMinutes = this.state.availableMinutes.find( minute => {
            return minute == this.state.selectedMinutes;
        });

        if (!hasMinutes) {
            if (this.state.availableMinutes.length > 0) {
                this.setState({selectedMinutes: this.state.availableMinutes[0]}, this.sendEvent);
            } else {
                this.validateLastHour();
            }
        } else {
            this.sendEvent();
        }
    }

    /**
     * when changing hours set the first available time if the previously selected is not available
     */
    hoursSelectedOnList() {
        const hasHours = this.state.availableHours.find( hour => {
            return hour == this.state.selectedHour;
        });

        if (!hasHours) {
            this.setState({selectedHour: this.state.availableHours[0]}, this.minutesSelectedOnList);
        } else {
            this.minutesSelectedOnList()
        }
    }

    // The fraction of time is empty
    isValidTime(minute, hour, period) {
        let time = moment(`${hour}:${minute} ${period}`, ["h:mm A"]);
        const a = parseInt(time.format('HH'), 10);
        const b = parseInt(time.format('mm'), 10);
        const index = (a * (60 / this.timeFraction)) + (b / this.timeFraction);
        return !this.timeFrames[index];
    }

    handleChange = () => {
        this.hoursSelectedOnList();
    }

    // run the handleOnChange event
    sendEvent() {
        const { dateStr, timezone } = this.props;
        if (this.props.handleOnChange) {
            let hour = parseInt(this.state.selectedHour);
            if (this.state.selectedPeriod === 'PM' && hour !== 12) hour += 12;
            if (this.state.selectedPeriod === 'AM' && hour === 12) hour = 0;

            const mom = moment.tz(new Date(DateUtils.displayLongDateFromStrEng(dateStr)).toUTCString(), timezone);
            const arr = dateStr.split('-');
            const year = parseInt( arr[0], 10 );
            const month = parseInt( arr[1], 10 );
            const day = parseInt( arr[2], 10 );
            mom.set({year: year, month: (month-1), date: day, hour: hour, minute: this.state.selectedMinutes});
            this.props.handleOnChange(mom.valueOf());
        }

    }

    onHourChange = (event) => {
        this.setState({selectedHour: event.target.value}, this.validateLastHour);
    }

    onMinutesChange = (event) => {
        this.setState({selectedMinutes: event.target.value}, this.handleChange);
    }

    onPeriodChange = (event) => {
        this.setState({selectedPeriod: event.target.value}, this.getValidHours);
    }

    render() {
        const { availableMinutes, availableHours } = this.state;
        return (
            <>
                <select onChange={this.onHourChange} defaultValue={this.selectedHour} value={this.state.selectedHour}>
                    {
                        availableHours.map( hour => {
                            return <option key={'hour-' + hour} value={hour}>{hour}</option>
                        })
                    }
                </select>
                <select onChange={this.onMinutesChange} defaultValue={this.selectedMinutes} value={this.state.selectedMinutes}>
                    {
                        availableMinutes.map( minute => {
                            return <option key={'minute-' + minute} value={minute}>{('0' + minute).slice(-2)}</option>
                        })
                    }
                </select>
                <select onChange={this.onPeriodChange} defaultValue={this.selectedPeriod} value={this.state.selectedPeriod}>
                    <option value='AM' key={'period-AM'}>AM</option>
                    <option value='PM' key={'period-PM'}>PM</option>
                </select>
            </>
        );
    }
}

export default TimePicker;