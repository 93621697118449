import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './Admin.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import { withTranslation, Trans } from 'react-i18next';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { inject, observer } from 'mobx-react';
import DateUtils from '../../Utils/DateUtils';
import LoggedInUser from '../Account/LoggedInUser';
import InComponentModal from '../Modal/InComponentModal';
import moment from 'moment';
import i18n from "i18next";

class AdminClubReservations extends Component {

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    state = {
        loading: true,
        date: new Date(),
        dateStr: '',
        club_ident: null,
        club: null,
        hasMultiple: false,
        showModalDefaults: false,
        savingDefaults: false,
        showModalDefaultsMessage: false,

        showModalAdd: false,
        addState: 'form', // ['finding', 'missing', 'verify', 'creating', 'form']
        addMember: null,
        addTimeSlotInt: null,
        addSpotType: null,
        keyfob: '',
        email: '',
        last_name: '',

        showModalCancel: false,
        cancelReservationId: null,
        isCancelling: false,

        daily_counts: [],
        hoursArr: [],
        senior_club_open: '5:00 AM',
        senior_club_close: '8:00 AM',
        is_senior: false,
        workout_minutes: 60,
        cleaning_minutes: 30,
        advance_spots_per_time_slot: 10,
        walkin_spots_per_time_slot: 10,
        staff_spots_per_time_slot: 2
    };

    async componentDidMount() {
        if ( !this.props.UserStore.isLogged() ) return this.props.UserStore.forceLogin( true );
        // Should we show the link back to "Manage"?
        let clubList = {};
        if ( !this.props.UserStore.isCompanyAdmin() ) {
            clubList = await window.muuv.get( '/clubs?is_editable=1&per_page=1' );
        }
        const { club_ident } = this.props.match.params;
        let club = await window.muuv.get( '/clubs/' + club_ident );
        if ( club.error ) return alert( club.error );
        let dateStr;
        let date;
        if (!this.props.ReservationsStore.selectedDate) {
            dateStr = DateUtils.inputDate( new Date(), club.timezone );
            date = DateUtils.dateFromStr( dateStr );
        } else {
            date = this.props.ReservationsStore.selectedDate;
            dateStr = DateUtils.inputDate(date);
        }
        this.setState({
            club_ident: club_ident,
            club: club,
            date: date,
            dateStr: dateStr,
            hasMultiple: ( this.props.UserStore.isCompanyAdmin() || ( clubList && clubList.total > 1 ) )
        }, this.getClubData.bind( this ) );
    }

    async getClubData() {
        const { t } = this.props;
        let club = await window.muuv.get( '/clubs/' + this.state.club_ident + '?date=' + this.state.dateStr + '&include_reservations=1&include_club=1' );
        if ( club.error ) return alert( club.error );
        if ( !club ) return alert('Sorry, that club is missing in our system.');

        club = this.modifyClubData( club );
        let daily_counts = await window.muuv.get(`/clubs/${ this.state.club_ident }/reservations/daily_counts`);

        // Make sure I can edit this club.
        if ( !this.props.UserStore.canAdminClub( club.ident ) ) {
            return this.props.history.push( '/clubs' );
        }
        document.title = t('admin.club_reservations.document_title') + club.name;
        const en = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( en('admin.club_reservations.document_title') + club.name );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('admin.club_reservations.document_title') + club.name,
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );

        this.setState({
            club,
            hoursArr: this.figureHoursArrFromObject( club.hours ),
            senior_club_open: ( club.hours.senior ? club.hours.senior.open : '5:00 AM' ),
            senior_club_close: ( club.hours.senior ? club.hours.senior.close : '8:00 AM' ),
            is_senior: !!club.hours.senior,
            workout_minutes: club.workout_minutes,
            cleaning_minutes: club.cleaning_minutes,
            advance_spots_per_time_slot: club.advance_spots_per_time_slot,
            walkin_spots_per_time_slot: club.walkin_spots_per_time_slot,
            staff_spots_per_time_slot: club.staff_spots_per_time_slot,
            loading: false,
            daily_counts
        });
        this.props.ModalStore.closeModal();
        this.props.ModalStore.closeInComponent();
    }

    modifyClubData( club ) {
        club.total_available = 0;
        club.time_slots.forEach( timeSlot => {
            if ( !timeSlot.total_spots_open ) timeSlot.total_spots_open = 0;
            club.total_available += timeSlot.total_spots_open;
            timeSlot.advance_reservations = [];
            timeSlot.walkin_reservations = [];
            if ( timeSlot.reservations ) {
                timeSlot.reservations.forEach( res => {
                    if ( res.spot_type === 'advance' ) {
                        timeSlot.advance_reservations.push( res );
                    } else if ( res.spot_type === 'walkin' ) {
                        timeSlot.walkin_reservations.push( res );
                    }
                });
            }
        });

        club.formatted_hours_str = '';
        club.senior_hours_str = null;
        if ( club.hours.simple ) {
            club.formatted_hours_str = DateUtils.displayTimeFromStr( club.hours.simple.open ) + ' - ' + DateUtils.displayTimeFromStr( club.hours.simple.close );
/*
        } else {
            let dayStrs = [];
            DateUtils.weekdays().forEach( ( weekday, i ) => {
                if ( club.hours[ weekday ] && club.hours[ weekday ].length > 0 ) {
                    let dayStr = '';
                    dayStr += window.muuv.displaySmallWeekday( i );
                    dayStr += ': ';
                    let arr = [];
                    club.hours[ weekday ].forEach( weekdayHourObj => {
                        let str = '';
                        str += window.muuv.displayTimeFromStr( weekdayHourObj.open );
                        str += '-';
                        str += window.muuv.displayTimeFromStr( weekdayHourObj.close );
                        arr.push( str );
                    });
                    dayStr += arr.join(', ');
                    dayStrs.push( dayStr );
                }
            });
            club.formatted_hours_str = dayStrs.join(', ');
*/
        }
        if ( club.hours.senior ) {
            club.senior_hours_str = DateUtils.displayTimeFromStr( club.hours.senior.open ) + ' - ' + DateUtils.displayTimeFromStr( club.hours.senior.close );
        }
        club.full_address = window.muuv.getFullAddress( club );

        return club;
    }

    figureHoursArrFromObject( hoursObj ) {
        if ( hoursObj.simple ) {
            return [{
                days: DateUtils.weekdays(),
                open: hoursObj.simple.open,
                close: hoursObj.simple.close
            }]
        }
        let hours = {};
        let hoursArr = [];
        DateUtils.weekdays().forEach( weekday => {
            if ( hoursObj[ weekday ] ) {
                hoursObj[ weekday ].forEach( weekdayHourObj => {
                    let hoursIdent = weekdayHourObj.open.replace(/ /g, '-') + '-' + weekdayHourObj.close.replace(/ /g, '-');
                    if ( !hours[ hoursIdent ] ) {
                        hours[ hoursIdent ] = {
                            days: [],
                            open: weekdayHourObj.open,
                            close: weekdayHourObj.close,
                            senior: weekdayHourObj.senior,
                            advance_spots_per_time_slot: weekdayHourObj.advance_spots_per_time_slot,
                            cleaning_minutes: weekdayHourObj.cleaning_minutes,
                            staff_spots_per_time_slot: weekdayHourObj.staff_spots_per_time_slot,
                            walkin_spots_per_time_slot: weekdayHourObj.walkin_spots_per_time_slot,
                            workout_minutes: weekdayHourObj.workout_minutes
                        };
                    }
                    hours[ hoursIdent ].days.push( weekday );
                });
            }
        });
        for ( let hoursIdent in hours ) {
            hoursArr.push( hours[ hoursIdent ] );
        }
        return hoursArr;
    }

    handleCalendarChange( date ) {
        if (this.props.ModalStore.isOpenInComponent) return;
        this.props.ModalStore.openInComponent();
        this.props.ReservationsStore.selectedDate = date;
        // We intentionally don't convert to club timezone here.
        // Because the calendar is displaying in the user's timezone.
        // And if they select May 27th in their timezone, we want to show
        // May 27th in the club's timezone.
        let dateStr = DateUtils.inputDate( date );
        this.setState({
            date: date,
            dateStr: dateStr
        }, this.getClubData.bind( this ) );
    }

    // ========================
    // Add Modal

    handleAdd( e ) {
        e.preventDefault();
        this.setState({
            showModalAdd: true,
            addTimeSlotInt: parseInt( e.currentTarget.getAttribute( 'data-timeslot_int' ), 10 ),
            addSpotType: e.currentTarget.getAttribute( 'data-spot_type' )
        });
    }

    handleChooseMember(member){
        this.setState({
            addState: 'verify',
            addMember: member
        })
    }

    async handleAddSubmit(e) {
        e.preventDefault();
        const { keyfob, last_name, email } = this.state
        this.setState({ addState: 'finding' });

        const params = new URLSearchParams({
            keyfob,
            email,
            last_name
        }).toString()

        let member = await window.muuv.get( `/users/emailorkeyfob?${params}` );

        let newState = {
            addState: 'missing',
            addMember: null 
        } 

        switch (member.length) {
            case 0:
                newState = {
                    addState: 'missing',
                    addMember: null 
                }
                break;
            case 1:
                [member] = member
                newState = {
                    addState: 'verify',
                    addMember: member
                }
                break;
            default:
                newState = {
                    addState: 'choose',
                    addMember: member
                }
                break;
        }

        this.setState(newState)
    }

    async handleAddCreate(e) {
        const { t } = this.props;
        e.preventDefault();
        this.setState({ addState: 'creating' });
        let postObj = {
            club_ident: this.state.club.ident,
            spot_type: this.state.addSpotType,
            start_int: this.state.addTimeSlotInt
        };
        if ( this.state.addMember.member_id ) {
            postObj.member_ident = this.state.addMember.member_id;
            postObj.member_club_ident = this.state.addMember.club_number;
        } else {
            postObj.user_ident = this.state.addMember.user_id;
        }
        const reservation = await window.muuv.post( '/reservations', postObj );
        await this.getClubData();
        if (reservation.error_keys && reservation.error_keys.length > 0) {
            alert(t(reservation.error_keys[0]));
        } else if (reservation.error) {
            alert(reservation.error);
        } else {
            this.sendResAdminCreatedEventToAnalytics(reservation);
        }
        this.closeModalAdd();
    }

    sendResAdminCreatedEventToAnalytics(reservation) {
        const evt = {
            "club_country": this.state.club.address_country,
            "club_id": this.state.club.id,
            "club_state": this.state.club.address_state,
            "club_tz": this.state.club.timezone,
            "club_ident": this.state.club.ident,
            "club_city": this.state.club.address_city,
            "club_name": this.state.club.name,
            "club_postal_code": this.state.club.address_zip,
            "reservation_id": reservation.id,
            "reservation_end_time": reservation.stop_time,
            "reservation_type": reservation.spot_type,
            "reservation_date": reservation.date,
            "reservation_start_time": reservation.start_time,
            "user_id": reservation.user_id,
            "user_ident": reservation.user_ident
        }
        window.muuv.analytics.logEvent('reservation_admin_created', evt);
    }


    handleAddReset(e) {
        e.preventDefault();
        this.setState({
            addState: 'form',
            addMember: null
        });
    }

    closeModalAdd(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' ) ) return;
        }
        this.setState({
            showModalAdd: false,
            addState: 'form',
            foundMember: null,
            email: '',
            keyfob: '',
            last_name: '',
            addTimeSlotInt: null,
            addSpotType: null
        });
    }

    // ========================
    // Cancel Modal

    handleCancel(e) {
        e.preventDefault();
        this.setState({
            showModalCancel: true,
            cancelReservationId: e.currentTarget.getAttribute( 'data-reservation_id' )
        });
    }

    async handleCancelSubmit(e) {
        e.preventDefault();
        if ( this.state.isCancelling ) return;
        this.setState({ isCancelling: true });
        let res = await window.muuv.delete( '/reservations/' + this.state.cancelReservationId );
        if ( res.error ) {
            alert( res.error );
        } else {
            this.sendResAdminCanceledToAnalytics(res);
        }
        await this.getClubData();
        this.closeModalCancel();
    }

    sendResAdminCanceledToAnalytics(reservation) {
        const evt = {
            "club_country": this.state.club.address_country,
            "club_id": this.state.club.id,
            "club_state": this.state.club.address_state,
            "club_tz": this.state.club.timezone,
            "club_ident": this.state.club.ident,
            "club_city": this.state.club.address_city,
            "club_name": this.state.club.name,
            "club_postal_code": this.state.club.address_zip,
            "reservation_id": reservation.id,
            "reservation_end_time": reservation.stop_time,
            "reservation_type": reservation.spot_type,
            "reservation_date": reservation.date,
            "reservation_start_time": reservation.start_time,
            "user_id": reservation.user_id,
            "user_ident": reservation.user_ident
        }
        window.muuv.analytics.logEvent('reservation_admin_canceled', evt);
    }

    closeModalCancel(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' ) ) return;
        }
        this.setState({
            showModalCancel: false,
            isCancelling: false,
            cancelReservationId: null
        });
    }

    handleGoBack(e){
        this.setState({
            addState:'form' 
        })
    }

    // ========================
    // Defaults Modal

    handleDefaultsClick(e) {
        e.preventDefault();
        this.setState({ showModalDefaults: true });
    }

    async saveModalDefaults(e) {
        e.preventDefault();
        if ( this.state.savingDefaults ) return;
        this.setState({ savingDefaults: true });
        let hoursObj = this.getHoursObject();

        if (!hoursObj) {
            this.setState({
                savingDefaults: false
            });
            return;
        }

        this.sendSaveDefaultsEventToAnalytics(hoursObj);
        let newClub = await window.muuv.put( '/clubs/' + this.state.club.ident, {
            hours: hoursObj,
        });
        if ( newClub.error ) {
            alert( newClub.error );
            this.setState({
                savingDefaults: false,
                showModalDefaults: false
            });
        } else {
            this.setState({
                savingDefaults: false,
                showModalDefaultsMessage: true,
                showModalDefaults: false
            }, this.getClubData.bind( this ) );
        }
    }

    sendSaveDefaultsEventToAnalytics(hoursObj) {
        const evt = {
            "club_hours": JSON.stringify(hoursObj),
        }
        window.muuv.analytics.logEvent('club_defaults_edited', evt);
    }

    getHoursObject() {
        const { t } = this.props;
        let hours = {};
        DateUtils.weekdays().forEach( weekday => {
            hours[ weekday ] = [];
        });
        this.state.hoursArr.forEach( row => {
            row.days.forEach( weekday => {
                hours[ weekday ].push({
                    open: row.open,
                    close: row.close,
                    senior: row.senior,
                    workout_minutes: row.workout_minutes,
                    cleaning_minutes: row.cleaning_minutes,
                    staff_spots_per_time_slot: row.staff_spots_per_time_slot,
                    walkin_spots_per_time_slot: row.walkin_spots_per_time_slot,
                    advance_spots_per_time_slot: row.advance_spots_per_time_slot
                });
            });
        });
        if ( this.state.is_senior ) {
            hours.senior = { open: this.state.senior_club_open, close: this.state.senior_club_close };
        }
        if (!this.isEndTimeAfterBegining(hours)) {
            alert(t('admin.club_reservations.adjust_your_hours'));
            return null;
        }
        if (this.overlaps(hours)) {
            alert(t('admin.club_reservations.overlap_error'));
            return null;
        }
        return hours;
    }

    /**
     * check if the hours saved for each day of the week overlap, expected hours format as 12:00 AM or 3:00 PM as set in DateUtils.timeValues()
     * @param {*} hours
     */
    overlaps(hours) {

        for (let i = 0; i < DateUtils.weekdays().length; i++) {
            const weekday = DateUtils.weekdays()[i];
            /*
             As the DateUtils.timeValues() values used are divided in frames of fifteen minutes, the highest amount of intervals is 96 for 24 hours.
             So, for each day you will have at most 96 possible small time frames. Creating 96 intervales and fill in them as the hours come in we
             will prevent comparing each value several times.
             */
            let timeframes = new Array(96);
            for (let j = 0; j < hours[weekday].length; j++) {
                const openingTime = moment(hours[weekday][j].open, ["h:mm A"]);
                const closingTime = moment(hours[weekday][j].close, ["h:mm A"]);
                if(!this.setTimeframe(openingTime, closingTime, timeframes)) {
                    return true;
                };
            }
        }
        return false;
    }

    isEndTimeAfterBegining(hours) {
        for (let i = 0; i < DateUtils.weekdays().length; i++) {
            const weekday = DateUtils.weekdays()[i];
            for (let j = 0; j < hours[weekday].length; j++) {
                const openingTime = moment(hours[weekday][j].open, ["h:mm A"]);
                const closingTime = moment(hours[weekday][j].close, ["h:mm A"]);
                if(openingTime && closingTime && openingTime.isAfter(closingTime)) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * add a time frame and check if overlaps
     * @param {*} openingTime
     * @param {*} closingTime
     * @param {*} timeframes
     */
    setTimeframe(openingTime, closingTime, timeframes) {
        let i = (parseInt(openingTime.format("HH")) * 4) + (parseInt(openingTime.format("mm")) / 15);
        let j = (parseInt(closingTime.format("HH")) * 4) + (parseInt(closingTime.format("mm")) / 15);
        for (i; i < j; i++) {
            if (timeframes[i]) {
                return false;
            } else {
                timeframes[i] = true;
            }
        }
        return true;
    }

    closeModalDefaults(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || e.target.className !== 'blanket' ) return;
        }
        this.closeDefaults();
    }

    closeModalDefaultAction = () => {
        this.closeDefaults();
    }

    closeDefaults() {
        this.setState({
            hoursArr: this.figureHoursArrFromObject( this.state.club.hours ),
            showModalDefaults: false
        });
    }

    closeModalDefaultsMessage(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' && e.target.className !== 'modal-submit' ) ) return;
        }
        this.setState({ showModalDefaultsMessage: false });
    }

    handleInputChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleInputIntegerChange(e) {
        let obj = {};
        obj[ e.target.name ] = parseInt( e.target.value, 10 );
        this.setState( obj );
    }

    handleArrayChange = (hourObj, index) => {
        const arr = [...this.state.hoursArr];
        arr[index] = hourObj;
        this.setState({hoursArr: arr});
    }

    handleCheckboxClick() {
        this.setState({ is_senior: !this.state.is_senior });
    }

    getValidTimes( key ) {
        let self = this;
        let str = '';
        DateUtils.timeValues().forEach( timeStr => {
            str += '<option value="' + timeStr + '"' + ( self.state[ key ] === timeStr ? ' selected' : '' ) + '>' + DateUtils.displayTimeFromStr( timeStr ) +'</option>';
        });
        return (
            <select id={ key } name={ key } onChange={ self.handleInputChange.bind( self ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    handleHoursCheckbox(e) {
        e.preventDefault();
        let label = e.currentTarget;
        let ident = label.getAttribute( 'data-hours_day' );
        let weekday = ident.split('|')[0];
        let hoursIndex = parseInt( ident.split('|')[1], 10 );
        let hoursArr = JSON.parse( JSON.stringify( this.state.hoursArr ) );
        if ( label.className === 'unchecked' ) {
            label.className = 'checked';
            hoursArr[ hoursIndex ].days.push( weekday );
        } else {
            label.className = 'unchecked';
            hoursArr[ hoursIndex ].days.splice( hoursArr[ hoursIndex ].days.indexOf( weekday ), 1 )
        }
        this.setState({ hoursArr: hoursArr });
    }

    handleHoursDeleteClick(e) {
        e.preventDefault();
        let hoursIndex = parseInt( e.currentTarget.getAttribute( 'data-hours_index' ), 10 );
        let hoursArr = JSON.parse( JSON.stringify( this.state.hoursArr ) );
        hoursArr.splice( hoursIndex, 1 );
        this.setState({ hoursArr: hoursArr });
    }

    getValidOpenTimes( openOrClose, index ) {
        let str = '';
        DateUtils.timeValues().forEach( timeStr => {
            str += '<option value="' + timeStr + '"' + ( this.state.hoursArr[ index ][ openOrClose ] === timeStr ? ' selected' : '' ) + '>' + DateUtils.displayTimeFromStr( timeStr ) +'</option>';
        });
        return (
            <select data-rowindex={ index } data-rowtype={ openOrClose } onChange={ this.handleTimeChange.bind( this ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    handleTimeChange(e) {
        let hoursArr = JSON.parse( JSON.stringify( this.state.hoursArr ) );
        let index = parseInt( e.currentTarget.getAttribute( 'data-rowindex' ), 10 );
        let openOrClose = e.currentTarget.getAttribute( 'data-rowtype' );
        hoursArr[ index ][ openOrClose ] = e.target.value;
        this.setState({ hoursArr: hoursArr });
    }

    handleHoursAddClick(e) {
        e.preventDefault();
        let hoursArr = JSON.parse( JSON.stringify( this.state.hoursArr ) );
        hoursArr.push({
            days: DateUtils.weekdays(),
            open: '5:00 AM',
            close: '9:00 PM',
            senior: false,
            workout_minutes: 30,
            cleaning_minutes: 0,
            staff_spots_per_time_slot: 0,
            walkin_spots_per_time_slot: 0,
            advance_spots_per_time_slot: 0
        });
        this.setState({ hoursArr: hoursArr });
    }

    getWorkoutTimes(hourObj, index) {
        let self = this;
        let str = '';
        [ 30, 45, 60, 75, 90, 105, 120 ].forEach( min => {
            str += '<option value="' + min + '"' + ( hourObj.workout_minutes === min ? ' selected' : '' ) + '>' + min +' min</option>';
        });
        return (
            <select id="workout_minutes" name="workout_minutes" onChange={ (e) => this.handleSpotSelect( e, hourObj, index ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    getCleaningTimes(hourObj, index) {
        let self = this;
        let str = '';
        [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60 ].forEach( min => {
            str += '<option value="' + min + '"' + ( hourObj.cleaning_minutes === min ? ' selected' : '' ) + '>' + min +' min</option>';
        });
        return (
            <select id="cleaning_minutes" name="cleaning_minutes" onChange={ (e) => this.handleSpotSelect( e, hourObj, index ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    handleSpotSelect = (e, hourObj, index) => {
        const obj = {...hourObj};
        obj[e.target.name] = parseInt( e.target.value, 10 );
        this.handleArrayChange(obj, index);
    }

    getSpotsSelect( key, hourObj, index ) {
        let str = '';
        for (let i=0;i<101;i++) {
            str += '<option value="' + i + '"' + ( hourObj[ key ] === i ? ' selected' : '' ) + '>' + i +'</option>';
        }
        return (
            <select id={ key } name={ key } onChange={ (e) => this.handleSpotSelect( e, hourObj, index ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    setSeniorHour(hourObj, index) {
        const obj = {...hourObj, senior: !hourObj.senior};
        this.handleArrayChange(obj, index);
    }

    tileClassNameFunction({date}) {
        let self = this;
        const strDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        return self.state.daily_counts[strDate] && self.state.daily_counts[strDate] > 0 ? 'day-with-reservations' : '';
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        let now = new Date().getTime();
        let addTimeSlotTime = '';
        if ( self.state.loading ) return '';

        if ( self.state.addTimeSlotInt ) {
            addTimeSlotTime = 
            DateUtils.displayLongDateFromInt( self.state.addTimeSlotInt, self.state.club.timezone ) 
            + ' at ' + 
            DateUtils.displayTimeFromInt( self.state.addTimeSlotInt, self.state.club.timezone )
        }

        const hasEmail = self.state.email != ''
        const hasKeyfob = self.state.keyfob != ''
        const hasLastName = self.state.last_name != ''

        const requireKeyfob = !hasEmail && hasKeyfob 
        const requireEmail = !hasKeyfob && hasEmail

        const isEmailValid = hasEmail && document.getElementById("email")?.validity.valid 
        const isKeyfobValid = hasKeyfob && document.getElementById("keyfob")?.validity.valid
        const isLastNameValid = hasLastName && document.getElementById("last_name")?.validity.valid

        const isFormValid = hasLastName && (requireEmail || requireKeyfob)
        const isFindMemberDisabled = !isFormValid 

        const ChooseMember = ({member}) => {
            return (
                <div className='choose-member'>
                    <div className='member-name'>
                        <h2>
                            {member.first_name}
                            &nbsp;
                            {member.last_name}
                        </h2>
                    </div>
                    <div className='member-email'>
                        <h4>
                            {self.state.email}
                        </h4>
                    </div>
                    <div className="modal-small-field">
                        <a href="/" className="modal-submit" onClick={ self.handleChooseMember.bind( self, member ) } >{ t('admin.club_reservations.select') }</a>
                    </div>
                </div>
            )
        } 

        return (
            <div className="admin">

                <div className="admin-header">
                    <img alt="Anytime Fitness" src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                </div>
                <div className="admin-subheader">
                    <h1>
                        { self.state.hasMultiple && (
                            <Link className="admin-returnto" to="/admin/clubs">&larr; { t('admin.club_reservations.manage') }</Link>
                        )}
                        { t('admin.club_reservations.header') }
                    </h1>
                    <Grid item xs={4} md={2}>
                        <LoggedInUser></LoggedInUser>
                    </Grid>
                </div>

                <div className="admin-page">
                    <div className="admin-sidebar">
                        <div className="admin-sidebar-content">
                            <h2>
                                { self.state.club.name }
                                <div className="admin-club-address">
                                    { self.state.club.full_address }
                                </div>
                            </h2>

                            { !self.state.club.is_reservable && (
                                <div className="admin-sidebar-section">
                                    { t('admin.club_reservations.not_enabled') }
                                </div>
                            )}

                            <div className="admin-sidebar-section">
                                <Calendar onChange={ self.handleCalendarChange.bind( self ) } value={ self.state.date }
                                    tileClassName={self.tileClassNameFunction.bind(self)}
                                />
                            </div>

                            <div className="admin-sidebar-section">
                                <a className="admin-defaults-button" href="/" onClick={ self.handleDefaultsClick.bind( self ) }>{ t('admin.club_reservations.defaults') }</a>
                                <h3>{ t('admin.club_reservations.club_timeslots') }</h3>
                                <br />


                                <Link className="admin-managetimes-button" to={ `/admin/clubs/${ self.state.club.ident }/time_slots` }>{ t('admin.club_reservations.manage_individual_timeslots') }</Link>

                            </div>

                        </div>
                    </div>

                    <div className="admin-container">
                        <div className="admin-content-header">
                            <div className="admin-content-header-day">{ self.state.date.toLocaleDateString( this.props.UserStore.lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }</div>
                            <div className="admin-content-header-available">{ self.state.club.total_available } { t('admin.club_reservations.total_available') }</div>
                        </div>
                        <div className="admin-content">
                            <InComponentModal className='admin-in-modal'></InComponentModal>
                            { self.state.club.time_slots.map( ( timeSlot, i ) =>
                                <div key={ i } className="admin-timeslot">
                                    <div className="admin-timeslot-section">
                                        <div className="admin-timeslot-time">
                                            <p>
                                                <b>{ DateUtils.displayTimeFromInt( timeSlot.start_int, timeSlot.timezone ) }</b>
                                                { timeSlot.is_senior && (
                                                    <span className="admin-timeslot-senior">{ t('global.senior') }</span>
                                                )}
                                            </p>
                                        </div>
                                        <div className="admin-reservations">
                                            { (
                                                <div className="admin-reservations-type">
                                                    <div className="admin-reservations-count">
                                                        <b>{ timeSlot.advance_reservations.length }/{ timeSlot.advance_spots }</b>
                                                        <i>{ t('admin.advance') }</i>
                                                    </div>
                                                    <div className="admin-reservations-list">
                                                        { timeSlot.advance_reservations.map( ( res, i ) =>
                                                            <div key={ i } className="admin-reservations-row">
                                                                { !res.is_admin_created && <AccountBoxIcon style={{color:"#8348AD"}} fontSize="small"></AccountBoxIcon>}
                                                                <span className="admin-reservations-name">
                                                                    { res.user_name }
                                                                </span>
                                                                <span className="admin-reservations-ident">{ res.user_keyfob }</span>
                                                                <span className="admin-reservations-ident">{ `
                                                                    ${res.user_home_club && res.user_home_club.name ? res.user_home_club.name + ', ' : ''}
                                                                    ${res.user_home_club && res.user_home_club.address_state ? res.user_home_club.address_state : ''}` }
                                                                </span>
                                                                { timeSlot.stop_int > now && (
                                                                    <a href="/" className="admin-reservations-cancel" data-reservation_id={ res.id } onClick={ self.handleCancel.bind( self ) }><img src="/img/trash-can.svg" alt={ t('global.cancel') } /></a>
                                                                )}
                                                            </div>
                                                        )}
                                                        { timeSlot.stop_int > now && timeSlot.advance_spots_open > 0 && (
                                                            <div className="admin-reservations-row">
                                                                <a href="/" className="admin-reservations-add" data-timeslot_int={ timeSlot.start_int } data-spot_type="advance" onClick={ self.handleAdd.bind( self ) }>{ t('global.add_new') }</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            { (
                                                <div className="admin-reservations-type">
                                                    <div className="admin-reservations-count">
                                                        <b>{ timeSlot.walkin_reservations.length }/{ timeSlot.walkin_spots }</b>
                                                        <i>{ t('admin.walkin') }</i>
                                                    </div>
                                                    <div className="admin-reservations-list">
                                                        { timeSlot.walkin_reservations.map( ( res, i ) =>
                                                            <div className="admin-reservations-row">
                                                                <span className="admin-reservations-name">{ res.user_name }</span>
                                                                <span className="admin-reservations-ident">{ res.user_keyfob }</span>
                                                                <span className="admin-reservations-ident">{ `
                                                                    ${res.user_home_club && res.user_home_club.name ? res.user_home_club.name + ', ' : ''}
                                                                    ${res.user_home_club && res.user_home_club.address_state ? res.user_home_club.address_state : ''}` }
                                                                </span>
                                                                { timeSlot.stop_int > now && (
                                                                    <a href="/" className="admin-reservations-cancel" data-reservation_id={ res.id } onClick={ self.handleCancel.bind( self ) }><img src="/img/trash-can.svg" alt={ t('global.cancel') } /></a>
                                                                )}
                                                            </div>
                                                        )}
                                                        { timeSlot.stop_int > now && timeSlot.walkin_spots_open > 0 && (
                                                            <div className="admin-reservations-row">
                                                                <a href="/" className="admin-reservations-add" data-timeslot_id={ timeSlot.id } data-timeslot_int={ timeSlot.start_int } data-spot_type="walkin" onClick={ self.handleAdd.bind( self ) }>{ t('global.add_new') }</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            { (
                                                <div className="admin-reservations-type admin-reservations-staff">
                                                    <div className="admin-reservations-count">
                                                        <i>{ timeSlot.staff_spots } { t('admin.staff') }</i>
                                                    </div>
                                                    <div className="admin-reservations-list">
                                                        <div className="admin-reservations-row">
                                                            ({ t('admin.club_reservations.reserved') })
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    { !!timeSlot.cleaning_minutes && (
                                        <div className="admin-timeslot-section admin-timeslot-cleaning">
                                            <div className="admin-timeslot-time">
                                                <p>{ DateUtils.displayTimeFromInt( timeSlot.start_int + ( timeSlot.workout_minutes * 60 * 1000 ), timeSlot.timezone ) }</p>
                                            </div>
                                            <div className="admin-reservations-type">
                                                <div className="admin-reservations-count">
                                                    { timeSlot.cleaning_minutes } { t('global.min') }
                                                </div>
                                                <div className="admin-reservations-list">
                                                    <div className="admin-reservations-row">
                                                        { t('admin.club_reservations.cleaning') }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                { self.state.showModalDefaults && (
                    <div className="blanket" onClick={ self.closeModalDefaults.bind( self ) }>
                        <div className="modal">
                            <div className="modal-header">
                                <h3>{ t('admin.club_reservations.edit_timeslot_defaults') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-field">
                                    <div className="modal-field-input" style={{width: '100%'}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{ t('admin.club_reservations.club_hours') }</th>
                                                    <th>{ t('admin.club_reservations.reservation_duration') }</th>
                                                    <th>{ t('admin.club_reservations.cleaning_duration') }</th>
                                                    <th>{ t('admin.club_reservations.senior_hours') }</th>
                                                    <th>{ t('admin.club_reservations.advance_reservation_spots') }</th>
                                                    <th>{ t('admin.club_reservations.walkin_reservation_spots') }</th>
                                                    <th>{ t('admin.club_reservations.staff_reservation_spots') }</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { self.state.hoursArr.map( ( hourObj, i ) =>
                                                <tr key={ i }>
                                                    <td>
                                                        <div className="modal-hours">
                                                            <div className="modal-hours-days">
                                                                { DateUtils.weekdays().map( ( weekday, j ) =>
                                                                <a href="/" key={ j } data-hours_day={ `${weekday}|${i}` } className={ hourObj.days.indexOf( weekday ) > -1 ? 'checked' : 'unchecked' } onClick={ self.handleHoursCheckbox.bind( self ) }>
                                                                    { DateUtils.displaySmallWeekday( j ) }
                                                                </a>
                                                                )}
                                                            </div>
                                                            <div className="modal-hours-times">
                                                                { self.getValidOpenTimes( 'open', i ) }
                                                                <span className="modal-field-input-to">{ t('global.to') }</span>
                                                                { self.getValidOpenTimes( 'close', i ) }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{ self.getWorkoutTimes(hourObj, i) }</td>
                                                    <td>{ self.getCleaningTimes(hourObj, i) }</td>
                                                    <td>
                                                        <FormControlLabel
                                                            onClick={() => { self.setSeniorHour(hourObj, i) }}
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={hourObj.senior}
                                                                />
                                                            }
                                                        />
                                                    </td>
                                                    <td>{ self.getSpotsSelect( 'advance_spots_per_time_slot', hourObj, i ) }</td>
                                                    <td>{ self.getSpotsSelect( 'walkin_spots_per_time_slot', hourObj, i ) }</td>
                                                    <td>{ self.getSpotsSelect( 'staff_spots_per_time_slot', hourObj, i ) }</td>
                                                    <td>
                                                        <a href="/" className="modal-hours-delete" data-hours_index={ i } onClick={ self.handleHoursDeleteClick.bind( self ) }><img src="/img/trash-can.svg" alt={ t('global.delete') } /></a>
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="modal-hours-add">
                                            <a href="/" onClick={ self.handleHoursAddClick.bind( self ) }><b>+</b> { t('admin.club_reservations.add_another_time') }</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="modal-footer">
                                    <a href="/" className="modal-done big cancel" onClick={ self.closeModalDefaultAction }>{ t('global.cancel') }</a>
                                    <a href="/" className="modal-done big" onClick={ self.saveModalDefaults.bind( self ) }>{ self.state.savingDefaults ? t('global.saving') + '...' : t('global.save') }</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                { self.state.showModalDefaultsMessage && (
                    <div className="blanket" onClick={ self.closeModalDefaultsMessage.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalDefaultsMessage.bind( self ) }>X</a>
                                <h3>{ t('admin.club_reservations.settings_updated') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-longmessage">
                                    { t('admin.club_reservations.settings_updated_information') }
                                </div>

                                <div className="modal-small-field">
                                    <a href="/" className="modal-submit" onClick={ self.closeModalDefaultsMessage.bind( self ) } >{ t('global.continue') }</a>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                { self.state.showModalAdd && (
                    <div className="blanket" onClick={ self.closeModalAdd.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalAdd.bind( self ) }>X</a>
                                <h3>
                                { self.state.addState != 'form' && (
                                    <button className='go-back' onClick={self.handleGoBack.bind(self)}>
                                    ⮜
                                    </button>
                                )}
                                &nbsp;
                                    { t('admin.club_reservations.enter_info') }
                                </h3>
                            </div>
                            { self.state.addState === 'form' && (
                                <form className="modal-form" action='/'>
                                    <div className="modal-small-field flex">
                                        <div className='dot-step'>
                                            {!isEmailValid 
                                                ? <div>1</div>
                                                : <div className='valid'>✓</div>
                                            }  
                                            <div>Required</div>
                                        </div>
                                        <div className='member-email'>
                                            <label htmlFor="email">{ t('admin.club_reservations.member_email') }</label>
                                            <input 
                                                disabled={self.state.keyfob != ""} 
                                                required={requireEmail} 
                                                id="email" name="email" type="email" className="text" value={ self.state.email } onChange={ self.handleInputChange.bind( self ) } />
                                        </div>
                                        <div>
                                            <h1>
                                                OR 
                                            </h1>
                                        </div>
                                        <div className='member-last-name'>
                                            <label htmlFor="keyfob">{ t('admin.club_reservations.member_keyfob_id') }</label>
                                            <input disabled={self.state.email != ""} 
                                                required={requireKeyfob} id="keyfob" name="keyfob" type="text" className="text" value={ self.state.keyfob } onChange={ self.handleInputChange.bind( self ) } />
                                        </div>
                                    </div>
                                    <div className="modal-small-field flex">
                                        <div className='dot-step'>
                                            {!isLastNameValid
                                                ? <div>2</div>
                                                : <div className='valid'>✓</div>
                                            }  
                                                <div>Required</div>
                                        </div>
                                        <div>
                                            <label htmlFor="last_name">{ t('admin.club_reservations.member_last_name') }</label>
                                            <input id="last_name" name="last_name" type="text" className="text" value={ self.state.last_name } onChange={ self.handleInputChange.bind( self ) } />
                                        </div>
                                    </div>

                                    <div className="modal-small-field flex-centered">
                                        <button 
                                            type='submit'
                                            disabled={isFindMemberDisabled}
                                            className="modal-submit" 
                                            onClick={ self.handleAddSubmit.bind( self ) } >
                                            <img src="/img/search.svg" alt={ t('global.search') } /> { t('admin.club_reservations.find_member') }
                                        </button>
                                    </div>
                                </form>
                            )}

                            { self.state.addState === 'finding' && (
                                <div className="modal-form">

                                    <div className="modal-working">
                                        { t('admin.club_reservations.finding_member') }...
                                    </div>

                                </div>
                            )}

                            { self.state.addState === 'missing' && (
                                <div className="modal-form">

                                    <div className="modal-message">
                                        { t('admin.club_reservations.sorry_couldnt_find_member') }
                                    </div>

                                    <div className="modal-small-field">
                                        <a href="/" className="modal-submit" onClick={ self.handleAddReset.bind( self ) } >{ t('admin.club_reservations.try_again') }</a>
                                    </div>

                                </div>
                            )}

                            { self.state.addState === 'choose' && (
                                <div className="modal-form">

                                    <div className="modal-message">
                                        { t('admin.club_reservations.multiple_members_found') }
                                    </div>

                                    Please select who you want to continue with: 

                                    <div className="modal-small-field flex choose-members">
                                        {self.state.addMember.map((member,i)=> <ChooseMember member={member} key={i}/>)} 
                                    </div>

                                </div>
                            )}

                            { self.state.addState === 'verify' && (
                                <div className="modal-form">

                                    <div className="modal-message">
                                        <Trans i18nKey="admin.club_reservations.please_confirm_add_reservation">
                                            Please confirm this is the member you want to add to the 
                                            <b>{{ addTimeSlotTime }}</b> timeslot:
                                        </Trans>
                                    </div>

                                    <div className="modal-member-info">
                                        <b>{ self.state.addMember.first_name } { self.state.addMember.last_name }</b>
                                        { self.state.keyfob && (<>
                                            <br />{ t('admin.club_reservations.keyfob_id') } <b>{ self.state.keyfob }</b>
                                        </>)}
                                        { self.state.addMember.member_id && (<>
                                            <br />{ t('admin.club_reservations.member_id') } <b>{ self.state.addMember.member_id }</b>
                                        </>)}
                                        { self.state.addMember.user_id && (<>
                                            <br />{ t('admin.club_reservations.user_id') } <b>{ self.state.addMember.user_id }</b>
                                        </>)}
                                        { self.state.addMember.club_number && (<>
                                            <br />{ t('admin.club_reservations.club_number') } <b>{ self.state.addMember.club_number }</b>
                                        </>)}
                                        { self.state.email && (<>
                                            <br />{ t('admin.club_reservations.email') } <b>{ self.state.email }</b>
                                        </>)}
                                    </div>

                                    <div className="modal-small-field">
                                        <a href="/" className="modal-submit" onClick={ self.handleAddCreate.bind( self ) } >{ t('admin.club_reservations.add_reservation') }</a>
                                    </div>

                                </div>
                            )}

                            { self.state.addState === 'creating' && (
                                <div className="modal-form">
                                    <div className="modal-working">
                                        { t('admin.club_reservations.creating_reservation') }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                { self.state.showModalCancel && (
                    <div className="blanket" onClick={ self.closeModalCancel.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalCancel.bind( self ) }>X</a>
                                <h3>{ t('admin.club_reservations.cancel_reservation') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-message">
                                    { t('admin.club_reservations.are_you_sure_cancel_reservation') }
                                </div>

                                <div className="modal-small-field">
                                    <a href="/" className="modal-submit" onClick={ self.handleCancelSubmit.bind( self ) } >{ self.state.isCancelling ? t('admin.club_reservations.cancelling') : t('admin.club_reservations.yes_remove') }</a>
                                </div>

                                { !self.state.isCancelling && (
                                    <div className="modal-small-field">
                                        <a href="/" className="modal-submit modal-submit-secondary" onClick={ self.closeModalCancel.bind( self ) } >{ t('global.no') }</a>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

AdminClubReservations = inject('UserStore', 'ModalStore', 'ReservationsStore')(observer(AdminClubReservations))
export default withTranslation()( AdminClubReservations );
