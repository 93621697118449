import React, { Component } from "react";
import { Grid, IconButton } from "@material-ui/core";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { inject, observer } from 'mobx-react';
import NavigationIcon from '@material-ui/icons/Navigation';
import locationIcon from '../../Images/Reservations/location.png';
import selectedLocationIcon from '../../Images/Reservations/selected-location.png';
import './ClubMap.scss';

class ClubMap extends Component {

    geoJson = {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': []
        }
    }
    selectedGeo = {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': []
        }
    }
    lastMapEvent = new Date().getTime();
    preventRefresh = false;

    componentDidMount() {
        this.props.ClubFinderStore.findCurrentLocation();
        const {MAPBOX_TOKEN, viewport} = this.props.ClubFinderStore;
        mapboxgl.accessToken = MAPBOX_TOKEN;
        let map = new mapboxgl.Map({
            container: this.mapRef,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [viewport.longitude, viewport.latitude],
            pitch: 0,
            zoom: viewport.zoom
        });

        this.props.ClubFinderStore.mapRef = map;
        let interval = setInterval(() => {
            if (map.isStyleLoaded()) {
                this.setMapProperties(map);
                clearInterval(interval);
            }
        });

    }

    setMapProperties(map) {

        map.addSource('points', this.geoJson);
        map.addSource('selected-point', this.selectedGeo);

        map.on('moveend', this.refreshBounds.bind(this, map));

        let locationImage = new Image();
        locationImage.src = locationIcon;
        locationImage.width = 40;
        locationImage.height = 40;
        locationImage.onload = () => {map.addImage('location', locationImage );};

        let selectedLocationImage = new Image();
        selectedLocationImage.src = selectedLocationIcon;
        selectedLocationImage.width = 40;
        selectedLocationImage.height = 40;
        selectedLocationImage.onload = () => {map.addImage('selected-location', selectedLocationImage );};

        map.addLayer({
            id: 'club-points',
            type: 'symbol',
            source: 'points',
            layout: {
                'icon-image': 'location',
                'icon-size': 1,
                'icon-allow-overlap': true
            }
        });
        map.addLayer({
            id: 'selected-point',
            type: 'symbol',
            source: 'selected-point',
            layout: {
                'icon-image': 'selected-location',
                'icon-size': 1,
                'icon-allow-overlap': true
            }
        });

        map.on('click', 'club-points', this.clickClub.bind(this, map));

        map.on('mousemove', 'club-points', function(e) {
            map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'club-points', function() {
            map.getCanvas().style.cursor = '';
        });

        map.resize();
    }
    refreshBounds(map) {
        this.lastMapEvent = new Date().getTime();
        setTimeout(() => {
            const timeBetweenClicks = new Date().getTime() - this.lastMapEvent;
            if (timeBetweenClicks > 800) {
                this.props.ClubFinderStore.viewport.longitude = map.getCenter().lng.toFixed(4);
                this.props.ClubFinderStore.viewport.latitude = map.getCenter().lat.toFixed(4);
                this.props.ClubFinderStore.viewport.zoom = map.getZoom().toFixed(2);
                this.props.ClubListingsStore.setClubsOnMapBoundaries(map, this.preventRefresh);
                this.preventRefresh = false;
            }
        }, 850)
    }

    clickClub(map, e) {
        this.props.ClubListingsStore.highlightedClubId = e.features[0].properties.id;
        this.preventRefresh = true;
        map.flyTo({ center: e.features[0].geometry.coordinates });
    }

    findCurrentLocation() {
        this.props.ClubFinderStore.findCurrentLocation();
    }

    componentWillUnmount() {
        this.props.ClubFinderStore.mapRef = null;
    }

    render() {
        return (
            <Grid item xs={12} className='club-map'>
                <div ref={el => this.mapRef = el} className='map-container'>
                    <IconButton aria-label="upload picture" component="span" className='current-location-button'
                        onClick={this.findCurrentLocation.bind(this)}>
                        <NavigationIcon className='navigation-icon' style={{color: '#2A98F5'}}></NavigationIcon>
                    </IconButton>
                </div>
            </Grid>
        );
    }
}

ClubMap = inject('ClubFinderStore', 'ClubListingsStore')(observer(ClubMap));
export default ClubMap;