import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import 'moment-timezone';
import App from './Components/App/App';
import { Provider } from 'mobx-react';
import * as stores from './Stores';
import UserStore from './Stores/UserStore';
import * as firebase from "firebase/app";
import 'firebase/analytics';
import Analytics from './Components/Analytics/Analytics';

function initReact() {

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter>
            <Route component={ App } exact />
            <Analytics />
        </BrowserRouter>
    </Provider>
, document.getElementById('root'));

}

window.muuv = {
    api: 'https://api.muuvlabs.com/anytime',
    memberauth: 'https://memberauth.anytimefitness.com/',
    authorize: 'https://authorize.anytimefitness.com/',
    client_id: 'aba01f0e-c3b8-416d-81de-81996bfb5e3e',
    me: {},
    language: 'en',
    translations: {},
    analytics: null,
    setApi: function() {
        // The default is set to Production,
        // But overwriting that based on the hostname we see.
        let host = window.location.hostname;
        // Local
        if ( host.indexOf('anytime-reservations.muuvlabs.local') > -1 ) {
            window.muuv.api = '//api.muuvlabs.local/anytime';
            window.muuv.memberauth = 'https://stage-memberauth.anytimefitness.com/';
            window.muuv.authorize = 'https://stage-authorize.anytimefitness.com/';
        // Dev
        } else if ( host.indexOf('localhost') > -1
                    || host.indexOf('anytime-reservations-dev.muuvlabs.com') > -1 ) {
            window.muuv.api = 'https://api-dev.muuvlabs.com/anytime';
            window.muuv.memberauth = 'https://stage-memberauth.anytimefitness.com/';
            window.muuv.authorize = 'https://stage-authorize.anytimefitness.com/';
        // Staging
        } else if ( host.indexOf('anytime-reservations-staging.muuvlabs.com') > -1 ) {
            window.muuv.api = 'https://api-staging.muuvlabs.com/anytime';
            window.muuv.memberauth = 'https://stage-memberauth.anytimefitness.com/';
            window.muuv.authorize = 'https://stage-authorize.anytimefitness.com/';
        // Production: Just checking to make sure we're on https, and redirecting if not.
        } else if ( window.location.protocol !== 'https:' ) {
            window.location.href = 'https://' + host + window.location.pathname + window.location.search + window.location.hash;
        }
    },
    param: function(name) {
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)",
            regex = new RegExp(regexS),
            results = regex.exec( window.location.search );
        if ( results === null ) return "";
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    getFullAddress: function( club ) {
        let fullAddress = club.address_line1;
        if ( club.address_line2 ) {
            fullAddress += ', ' + club.address_line2;
        }
        fullAddress += ', ' + club.address_city;
        fullAddress += ', ' + club.address_state;
        fullAddress += ' ' + club.address_zip;
        fullAddress += ' ' + club.address_country;
        return fullAddress;
    },
    get: function( path ) {
        path += ( path.indexOf('?') > -1 ? '&' : '?' ) + 'ran=' + new Date().getTime();
        return fetch( window.muuv.api + path, {
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            }
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    post: function( path, data ) {
        return fetch( window.muuv.api + path, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    put: function( path, data ) {
        return fetch( window.muuv.api + path, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    delete: function( path, data ) {
        return fetch( window.muuv.api + path, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    initFirebase: async function() {
        // Local, Dev, Staging
        let firebaseConfig = {
            apiKey: "AIzaSyC7eQe-MRgjfZSfN_pUvJh3NCuupzX_fI0",
            authDomain: "muuv-labs-firebase.firebaseapp.com",
            databaseURL: "https://muuv-labs-firebase.firebaseio.com",
            projectId: "muuv-labs-firebase",
            storageBucket: "muuv-labs-firebase.appspot.com",
            messagingSenderId: "686893313239",
            appId: "1:686893313239:web:b81d94cc64e6c446045492",
            measurementId: 'G-V09KDH9NXR'
        };
        // Production
        if ( window.location.hostname === 'reserve.anytimefitness.com' ){
            firebaseConfig = {
               apiKey: "AIzaSyA3GcFuaHQfUrq4uMCW6dUt_FgwfWFFwbg",
               authDomain: "muuv-labs-anytime-prod.firebaseapp.com",
               databaseURL: "https://muuv-labs-anytime-prod.firebaseio.com",
               projectId: "muuv-labs-anytime-prod",
               storageBucket: "muuv-labs-anytime-prod.appspot.com",
               messagingSenderId: "722189937404",
               appId: "1:722189937404:web:681ca2baa4bb05b19b8579",
               measurementId: "G-F9JR0Z6RVW"
           };
        }
        await firebase.initializeApp( firebaseConfig );
        window.muuv.analytics = await firebase.analytics();
    }
};
function setUserInAnalytics(me) {
    if (!me.ident) {
        return;
    }
    const properties = {
        language: '',
        id: me.id,
        created_int: me.created_int,
        user_type: '',
        ident: me.ident,
        club: me.home_club_ident
    }
    if (me.languages && me.languages.length > 0) {
        properties.language = me.languages[0];
    }
    if (me.roles && me.roles.length) {
        properties.user_type = me.roles[0];
    }

    window.muuv.analytics.setUserId( me.ident );
    window.muuv.analytics.setUserProperties( properties );
}
window.muuv.setApi();
UserStore.getMe().then(async () => {
    await window.muuv.initFirebase().then(() => {
        setUserInAnalytics(UserStore.me);
        initReact();
    });

});
/*
window.muuv.get( '/users/me' ).then( async json => {
    window.muuv.applyMe( json );
    let lang = json.languages[0];
    if ( !lang || lang === 'undefined' ) lang = 'en';
    window.muuv.lang = lang;
    await i18n.changeLanguage( lang );
    if ( json.ident ) {
        window.muuv.analytics.setUserId( json.ident );
        window.muuv.analytics.setUserProperties( json );
    }
    initReact();
});*/
