import React from 'react';
import { withRouter } from 'react-router-dom';

class Analytics extends React.Component {

    /*constructor( props ) {
        super(props);
        window.muuv.analytics.setCurrentScreen( window.location.pathname + window.location.search );
        window.muuv.analytics.logEvent( 'page_view' );
    }*/

    /*componentDidUpdate({ location, history }) {
        if ( location.pathname === this.props.location.pathname ) {
            // don't log identical link clicks (nav links likely)
            return;
        }
        // When navigating forward, or hitting the back button.
        if ( history.action === 'PUSH' ||  history.action === 'POP' ){
            window.muuv.analytics.setCurrentScreen( window.location.pathname + window.location.search );
            window.muuv.analytics.logEvent( 'page_view' );
        }
    }*/
    render() {
        return null;
    }
}

export default withRouter(Analytics);
