import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReservatiosHeaderImage from '../../Images/Reservations/header.svg';
import SmallHeaderImage from '../../Images/Reservations/small-header.svg';
import './Header.scss';

import Grid from '@material-ui/core/Grid';
import LoggedInUser from '../Account/LoggedInUser';

class Header extends Component {

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const pathname = this.props.location.pathname;
        /* Paths where we use this kind of header. It will also add the first element
            of the pathname as class in case you have to apply a style for a single screen
        */
        const reservationsHeaderPaths = [
            '/',
            '/clubs',
            '/clubs/'
        ]
        if (reservationsHeaderPaths.indexOf(pathname) !== -1) {
            return (
                <Grid container className={`reservations-header ${pathname.replace(/^\/([^/]*).*$/, '$1')}`}>
                    <img className='image-desktop' onClick={this.goToMainScreen.bind(this)} alt='' src={ReservatiosHeaderImage}/>
                    <img className='image-mobile' onClick={this.goToMainScreen.bind(this)} alt='' src={SmallHeaderImage}/>
                    <LoggedInUser customClass={'fixed-middle-right'}></LoggedInUser>
                </Grid>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(Header);
