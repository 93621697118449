import React, { Component } from 'react';
import WarningIcon from '@material-ui/icons/Warning';

import './Maintenance.scss';
import { withTranslation } from 'react-i18next';

class Maintenance extends Component {
    render() {
        const { t } = this.props
        return (
            <div className='maintenance-component'>
                <div className='maintenance-icon'>
                    <WarningIcon className='maintenance-icon' fontSize='large'></WarningIcon>
                </div>
                <div className='maintenance-message'>
                    <p>{ t('global.maintenance') }</p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Maintenance);
