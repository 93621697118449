import React, { Component } from "react";
import { Link } from "react-router-dom";
import './ClubReservations.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import HeaderImageMobile from '../../Images/Reservations/small-header.png';
import { withTranslation } from 'react-i18next';
import { observer, inject } from "mobx-react";
import DateUtils from "../../Utils/DateUtils";
import i18n from "i18next";
import LoggedInUser from "../Account/LoggedInUser";

class Reservations extends Component {

    state = {
        loading: true,
        pastReservations: [],
        futureReservations: []
    };

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    async componentDidMount() {
        if ( !this.props.UserStore.isLogged() ) return this.props.UserStore.forceLogin();
        let reservations = await window.muuv.get( '/reservations?include_club=1&per_page=500' );
        if ( reservations.error ) return alert( reservations.error );
        let pastReservations = [];
        let futureReservations = [];
        let now = new Date().getTime();

        for ( const res of reservations.results ) {
            if ( res.stop_int < now ) {
                pastReservations.push( res );
            } else {
                futureReservations.push( res );
            }
        }

        pastReservations.sort((a, b) => (a.start_int < b.start_int) ? 1 : -1);
        futureReservations.sort((a, b) => (a.start_int > b.start_int) ? 1 : -1);

        this.setState({
            loading: false,
            pastReservations: pastReservations,
            futureReservations: futureReservations
        });
        this.props.ModalStore.closeModal();

        const { t } = this.props;
        document.title = t('manage.document_title');
        const en = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( en('manage.document_title') );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('manage.document_title'),
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
    }

    async handleCancelClick(e) {
        e.preventDefault();
        const { t } = this.props;
        if ( window.confirm( t('manage.reservations.confirm_cancel') ) ) {
            let reservationId = e.currentTarget.getAttribute( 'data-reservation_id' );
            let deleted = await window.muuv.delete( '/reservations/' + reservationId );
            if ( deleted.error ) {
                alert( deleted.error );
            } else {
                this.sendReservationCanceledEventToAnalytics(deleted);
                let el = document.getElementById( `res_${ reservationId }` );
                el.parentNode.removeChild( el );
            }
        }
    }

    sendReservationCanceledEventToAnalytics(response) {
        const futureRes = this.state.futureReservations.find((element) => {
            return element.id === response.id;
        })
        if (futureRes && response) {
            const evt = {
                "club_country": futureRes.club.address_country,
                "club_id": response.club_id,
                "club_state": futureRes.club.address_state,
                "club_tz": futureRes.club.timezone,
                "club_ident": response.club_ident,
                "club_city": futureRes.club.address_city,
                "club_name": futureRes.club.name,
                "club_postal_code": futureRes.club.address_zip,
                "reservation_id": response.id,
                "reservation_end_time": response.stop_time,
                "reservation_type": response.spot_type,
                "reservation_date":	response.date,
                "reservation_start_time": response.start_time,
                "user_id": response.user_id,
                "user_ident": response.user_ident
            };
            window.muuv.analytics.logEvent('reservation_canceled', evt);
        }
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        if ( self.state.loading ) return '';

        return (
            <div className="res">

                <div className="res-header">
                    <img alt="Anytime Fitness" className='header-image-mobile' src={ HeaderImageMobile } onClick={this.goToMainScreen.bind(this)} />
                    <img alt="Anytime Fitness" className='header-image-desktop' src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                    <LoggedInUser customClass={'fixed-middle-right'}></LoggedInUser>
                </div>
                <div className="res-subheader">
                    <h1>&nbsp;</h1>
                </div>

                <div className="res-page">

                    <div className="res-gyminfo">
                        <h1>{ t('manage.header.title') }</h1>
                        <Link className="res-return" to="/clubs">&larr; { t('manage.header.select_gym') }</Link>
                    </div>

                    <div className="res-container">
                        <div className="res-content">
                            <div className="res-day">
                                <h3>{ t('manage.reservations.upcoming') }</h3>
                                <div className="res-day-times">
                                    { ( !self.state.futureReservations || self.state.futureReservations.length === 0 ) && (
                                        <div className="res-day-empty">
                                            { t('manage.reservations.no_upcoming') }
                                        </div>
                                    )}
                                    { self.state.futureReservations.map( ( res, i ) =>
                                        <div key={ i } className="res-existing" id={ `res_${ res.id }` }>
                                            <a href="/" className="res-existing-cancel" data-reservation_id={ res.id } onClick={ self.handleCancelClick.bind( self ) }>{ t('global.cancel') }</a>
                                            <div className="res-existing-datetime">
                                                { DateUtils.displaySmallDateFromInt( res.start_int, res.club.timezone ) }
                                                &nbsp;@&nbsp;
                                                { DateUtils.displayTimeFromInt( res.start_int, res.club.timezone ) }
                                            </div>
                                            <a href={ res.club.url } className="res-existing-name">
                                                { res.club.name }
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="res-day">
                                <h3>{ t('manage.reservations.past') }</h3>
                                <div className="res-day-times">
                                    { ( !self.state.pastReservations || self.state.pastReservations.length === 0 ) && (
                                        <div className="res-day-empty">
                                            { t('manage.reservations.no_past') }
                                        </div>
                                    )}
                                    { self.state.pastReservations.map( ( res, i ) =>
                                        <div key={ i } className="res-existing">
                                            <div className="res-existing-datetime">
                                                { DateUtils.displaySmallDateFromInt( res.start_int, res.club.timezone ) }
                                                &nbsp;@&nbsp;
                                                { DateUtils.displayTimeFromInt( res.start_int, res.club.timezone ) }
                                            </div>
                                            <a href={ res.club.url } className="res-existing-name">
                                                { res.club.name }
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Link className="res-newtime" to="/clubs">{ t('manage.reservations.cta') }</Link>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

Reservations = inject('UserStore', 'ModalStore')(observer(Reservations));
export default withTranslation()( Reservations );
