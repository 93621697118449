const loadApiUrl = () => {
    // The default is set to Production,
    // But overwriting that based on the hostname we see.
    let host = window.location.hostname;
    // Local
    if ( host.indexOf('anytime-reservations.muuvlabs.local') > -1 ) {
        return '//api.muuvlabs.local/anytime';
    // Dev
    } else if ( host.indexOf('localhost') > -1 || host.indexOf('anytime-reservations-dev.muuvlabs.com') > -1 ) {
        return 'https://api-dev.muuvlabs.com/anytime';
    // Staging
    } else if ( host.indexOf('anytime-reservations-staging.muuvlabs.com') > -1 ) {
        return 'https://api-staging.muuvlabs.com/anytime';
    // Production: Just checking to make sure we're on https, and redirecting if not.
    } else if ( window.location.protocol !== 'https:' ) {
        return 'https://' + host + window.location.pathname + window.location.search + window.location.hash;
    } else {
        return 'https://api.muuvlabs.com/anytime';
    }
}

const API_URL = loadApiUrl();


const requests = {
    get: function( path ) {
        path += ( path.indexOf('?') > -1 ? '&' : '?' ) + 'ran=' + new Date().getTime();
        return fetch( API_URL + path, {
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            }
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    post: function( path, data ) {
        return fetch( API_URL + path, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    put: function( path, data ) {
        return fetch( API_URL + path, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    delete: function( path, data ) {
        return fetch( API_URL + path, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'cookieproxy': document.cookie
            },
            body: JSON.stringify( data )
        }).then( response => {
            let session = response.headers.get('session');
            let sessionsig = response.headers.get('sessionsig');
            if ( session ) document.cookie = session;
            if ( sessionsig ) document.cookie = sessionsig;
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    }
}

const ClubLocations = {
    locations: () => {
        return requests.get('/clubs');
    },
    locationsInBoundaries: (n, w, s, e) => {
        return requests.get(`/clubs?w=${w}&s=${s}&e=${e}&n=${n}&per_page=40`);
    }
}

const Reservations = {
    reservations: (timestamp) => {
        return requests.get(`/reservations?stop_gt=${timestamp}`);
    }
}

const Users = {
    getMe: () => {
        return requests.get('/users/me');
    },
    setLanguage: (lang) => {
        const putObj = {
            "selected_language": lang
        };
        return requests.put('/users/me', putObj);
    },
    postToken: (tokenObj) => {
        return requests.post('/users/token', tokenObj);
    },
    postVerify: (verifyObject) => {
        return requests.post('/users/verify', verifyObject);
    },
    logout: (redirectUrl) => {
        const encodedRedirectUrl = encodeURIComponent(redirectUrl);
        const encodedUrl = encodeURIComponent(API_URL + '/users/logout?redirect=' + encodedRedirectUrl);
        window.location.href = `${window.muuv.authorize}/Account/Logout?redirectUrl=${encodedUrl}`;
    }
}

export default {
    ClubLocations,
    Reservations,
    Users
};
