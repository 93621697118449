import React, { Component } from 'react';
import '../Reservations/ClubReservations.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import { inject, observer } from 'mobx-react';

class AccountAuth extends Component {

    componentDidMount() {
        this.props.UserStore.verify();
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="res res-final">
                <div className="res-header">
                    <img alt="Anytime Fitness" src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                </div>

                <div className="res-final-error">
                    Logging you in...
                </div>

            </div>
        );
    }
}

AccountAuth = inject('UserStore')(observer(AccountAuth));
export default AccountAuth;
