import React, { Component } from "react";
import { Grid, Hidden } from "@material-ui/core";
import './ClubIndex.scss';
import ClubList from "../Map/ClubList";
import ClubMap from "../Map/ClubMap";
import ClubSearch from "../Map/ClubSearch";
import InComponentModal from "../Modal/InComponentModal";
import { withTranslation } from 'react-i18next';
import { observer, inject } from "mobx-react";
import i18n from "i18next";

class ClubIndex extends Component {

    async componentDidMount() {
        let clubParam = window.muuv.param('club');
        if ( clubParam ) {
            return this.props.history.push( '/clubs/' + clubParam );
        }
        const en = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( en('map.document_title') );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('map.document_title'),
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        const { t } = this.props;
        document.title = t('map.document_title');

    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className='club-index'>
                <Grid item xs={12} className='title'>{ t('map.header.title') }</Grid>
                <Grid item xs={12} className='club-selection-container'>
                    <Hidden smDown>
                        <Grid item md={4} className='desktop-list'>
                            <InComponentModal></InComponentModal>
                            <ClubSearch></ClubSearch>
                            <ClubList></ClubList>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={12} sm={12} md={4} className='club-search'>
                            <ClubSearch></ClubSearch>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={12} sm={12} md={4} className='club-list'>
                            <InComponentModal></InComponentModal>
                            <ClubList></ClubList>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={8} className='club-map-container'>
                        <ClubMap></ClubMap>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

ClubIndex = inject('UserStore')(observer(ClubIndex));
export default withTranslation()( ClubIndex );
