import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import './Modal.scss';


class InComponentModal extends Component {

    render() {
        const { isOpenInComponent  } = this.props.ModalStore;
        const styles = {
            modalVisibility: () => {
                if (isOpenInComponent) {
                    return {
                        display: 'flex',
                    }
                }else{
                    return {
                        display: 'none',
                    }
                }
            },
        }
        return(
            <div className={`app-modal in-component-modal ${this.props.className}`}
                style = {styles.modalVisibility()}
            >
                <div className='icon-container'>
                    <div className='loading-circle'>
                        <div className='circle'></div>
                    </div>
                    <AccessTimeIcon style={{color: 'white'}} fontSize='large'></AccessTimeIcon>
                </div>
            </div>
        )
    }
}

InComponentModal  = inject('ModalStore')(observer(InComponentModal))
export default InComponentModal;