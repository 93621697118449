import React, { Component } from "react";
import { Link } from "react-router-dom";
import './ClubReservations.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import HeaderImageMobile from '../../Images/Reservations/small-header.png';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { withTranslation, Trans } from 'react-i18next';
import { inject, observer } from "mobx-react";
import DateUtils from "../../Utils/DateUtils";
import LoggedInUser from "../Account/LoggedInUser";

class ClubReservations extends Component {

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    state = {
        loading: true,
        club_ident: null,
        club: null,
        resDates: [],
        timeSlotIds: [],
        selectedTime: null,
        selectedTimeSlotId: null,
        isSending: false,
        showModalReserved: false
    };

    async componentDidMount() {
        const { club_ident } = this.props.match.params;
        this.setState({
            club_ident: club_ident,
        }, this.getClubData.bind( this ) );
    }

    async getClubData() {
        const { t } = this.props;
        let club = await window.muuv.get( '/clubs/' + this.state.club_ident );
        if ( club.error ) return alert( club.error );
        document.title = t('club_reservations.document_title') + club.name;

        // TODO: This should be coming from a Store, not being fetched every time.
        // Get the current reservations for the user.
        let reservations = { results: [] };
        if ( this.props.UserStore.isLogged() ) {
            reservations = await window.muuv.get( '/reservations?per_page=500' );
        }
        let resDates = [];
        let timeSlotIds = [];
        if ( reservations.error ) return alert( reservations.error );
        reservations.results.forEach( res => {
            let resDate = DateUtils.inputDate( new Date( res.start_int ), club.timezone );
            if ( resDates.indexOf( resDate ) === -1 ) resDates.push( resDate );
            timeSlotIds.push( res.time_slot_id );
        });
        let currentDate = null;
        let currentDateInt = -1;
        club.available_times_count = 0;
        club.available_days = [];

        let isMember = this.props.UserStore.me.home_club_ident === club.ident;
        if ( this.props.UserStore.canAdminClub( club.ident ) ) {
            isMember = true;
        }
        club.time_slots.forEach( timeSlot => {
            if ( !this.props.UserStore.isTimeSlotValid( timeSlot )
                || (!isMember && club.available_times_count === 3)) return;
            let inputDate = DateUtils.inputDate( new Date( timeSlot.start_int ), club.timezone );
            if ( !currentDate || currentDate !== inputDate ) {
                currentDate = inputDate;
                currentDateInt++;
                club.available_days.push({
                    ident: inputDate,
                    is_already_reserved: ( resDates.indexOf( inputDate ) > -1 ),
                    time_slots: []
                });
            }
            club.available_days[ currentDateInt ].time_slots.push( timeSlot );
            club.available_times_count++;
        });
        club.full_address = window.muuv.getFullAddress( club );
        this.setState({
            club,
            resDates: resDates,
            timeSlotIds: timeSlotIds,
            loading: false
        });
        window.muuv.analytics.setCurrentScreen('Available Reservation Times');
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: 'Available Reservation Times',
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        this.props.ModalStore.closeModal();
    }

    handleTimeClick(e) {
        e.preventDefault();
        let startInt = parseInt( e.currentTarget.getAttribute( 'data-start_int' ), 10 );
        let timeSlotId = e.currentTarget.getAttribute( 'data-timeslot_id' );
        if ( this.state.selectedTime !== startInt ) {
            this.sendTimeSlotSelectedEventToAnalytics(timeSlotId)
        }
        let dayIdent = e.currentTarget.getAttribute( 'data-day_ident' );
        if ( this.state.timeSlotIds.indexOf( timeSlotId ) > -1 || this.state.resDates.indexOf( dayIdent ) > -1 ) {
            return this.setState({ showModalReserved: true });
        }
        if ( !this.props.UserStore.isLogged()) {
            this.props.UserStore.forceLogin();
        } else if ( this.state.selectedTime === startInt ) {
            this.setState({ selectedTime: null, selectedTimeSlotId: null });
        } else {
            this.setState({ selectedTime: startInt, selectedTimeSlotId: timeSlotId });
        }
    }

    sendTimeSlotSelectedEventToAnalytics(timeSlotId) {
        const timeSlot = this.state.club.time_slots.find((element) => {
            return element.id === timeSlotId;
        });
        if (timeSlot) {
            const evt = {
                "club_country": this.state.club.address_country,
                "club_id": this.state.club.id,
                "club_state": this.state.club.address_state,
                "club_tz": this.state.club.timezone,
                "club_ident": this.state.club.ident,
                "club_city": this.state.club.address_city,
                "club_name": this.state.club.name,
                "club_postal_code": this.state.club.address_zip,
                "id": timeSlotId,
                "timeslot_date": timeSlot.date,
                "duration": timeSlot.total_minutes,
                "timeslot_start_time": timeSlot.start_time
            };
            window.muuv.analytics.logEvent('timeslot_selected', evt);
        }
    }

    async handleConfirmClick(e) {
        e.preventDefault();
        if ( !this.props.UserStore.isLogged() ) return this.props.UserStore.forceLogin();
        if ( this.state.isSending ) return;
        this.setState({ isSending: true });
        let postObj = {
            club_ident: this.state.club.ident,
            start_int: this.state.selectedTime
        };
        this.sendTimeslotConfirmationEventToAnalytics();
        let response = await window.muuv.post( '/reservations', postObj );
        if ( response.error ) {
            if (response.error_keys && response.error_keys.length > 0) {
                this.setState({errorMessage: response.error_keys[0]});
            }
            this.sendReserveFailedEventoToAnalytics(response.error)
            this.setState({
                isSending: false,
                isErrored: true
            });
        } else {
            this.sendReservationCreatedEventToAnalytics(response);
            this.props.history.push( '/reservations/' + response.id );
        }
    }

    sendTimeslotConfirmationEventToAnalytics() {
        const timeslot = this.state.club.time_slots.find((element) => {
            return element.id === this.state.selectedTimeSlotId;
        });
        if (timeslot) {
            const evt = {
                "club_country": this.state.club.address_country,
                "club_id": this.state.club.id,
                "club_state": this.state.club.address_state,
                "club_tz": this.state.club.timezone,
                "club_ident": this.state.club.ident,
                "club_city": this.state.club.address_city,
                "club_name": this.state.club.name,
                "club_postal_code": this.state.club.address_zip,
                "id": this.state.selectedTimeSlotId,
                "timeslot_date": timeslot.date,
                "duration": timeslot.total_minutes,
                "timeslot_start_time": timeslot.start_time
            };
            window.muuv.analytics.logEvent('timeslot_confirmed', evt);
        }
    }

    sendReservationCreatedEventToAnalytics(response) {
        const evt = {
            "club_country": this.state.club.address_country,
            "club_id": response.club_id,
            "club_state": this.state.club.address_state,
            "club_tz": this.state.club.timezone,
            "club_ident": response.club_ident,
            "club_city": this.state.club.address_city,
            "club_name": this.state.club.name,
            "club_postal_code": this.state.club.address_zip,
            "reservation_id": response.id,
            "reservation_end_time": response.stop_time,
            "reservation_type": response.spot_type,
            "reservation_date":	response.date,
            "reservation_start_time": response.start_time,
            "user_id": response.user_ident,
            "user_ident": response.user_ident
        };
        window.muuv.analytics.logEvent('reservation_created', evt);
    }

    sendReserveFailedEventoToAnalytics(error) {
        const evt = {
            'fail_reason': error
        }
        window.muuv.analytics.logEvent('reserve_failed', evt);
    }

    handleSelectNew(e) {
        e.preventDefault();
        this.setState({
            loading: true,
            isSending: false,
            isErrored: false,
            selectedTime: null
        });
        this.getClubData();
    }

    handleLoginClick(e) {
        e.preventDefault();
        this.props.UserStore.forceLogin();
    }

    closeModalReserved(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' ) ) return;
        }
        this.setState({
            showModalReserved: false
        });
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        if ( self.state.loading ) return '';

        let clubName = self.state.club.name;
        if ( self.state.isSending ) {
            return (
                <div className="app-modal in-component-modal"
                    style={{ display: 'flex' }}
                >
                    <div className='icon-container'>
                        <div className='loading-circle'>
                            <div className='circle'></div>
                        </div>
                        <AccessTimeIcon style={{ color: 'white' }} fontSize='large'></AccessTimeIcon>
                    </div>
                </div>
            );
        } else if ( self.state.isErrored ) {
            let otherOpen = self.state.club.available_times_count - 1;
            if ( otherOpen < 0 ) otherOpen = 0;
            return (
                <div className="res res-final">
                    <div className="res-header">
                        <img alt="Anytime Fitness" className='header-image-mobile' src={ HeaderImageMobile } onClick={this.goToMainScreen.bind(this)} />
                        <img alt="Anytime Fitness" className='header-image-desktop' src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                        <LoggedInUser customClass={'fixed-middle-right'}></LoggedInUser>
                    </div>

                    <div className="res-final-error">
                        { t('club_reservations.error.title') }
                    </div>

                    <div className="res-final-message">
                        <Trans i18nKey="club_reservations.error.unable_to_reserve_at">
                            We were unable to reserve the time you wanted at <span>{{ clubName }}</span>.
                        </Trans>
                    </div>

                    {   this.state.errorMessage &&
                        <div className="res-final-message">
                            { t(this.state.errorMessage) }
                        </div>
                    }

                    <div className="res-final-datetimebox">
                        <p>{ DateUtils.displayDateNoYearFromInt( self.state.selectedTime, self.state.club.timezone ) }</p>
                        <p>{ DateUtils.displayTimeFromInt( self.state.selectedTime, self.state.club.timezone ) }</p>
                    </div>

                    <div className="res-final-message">
                        <Trans i18nKey="club_reservations.error.other_open_times" count={ otherOpen }>
                            There are <b>{{ otherOpen }}</b> other open times at this gym.
                        </Trans>
                    </div>

                    <a href="/" className="res-final-selectnew" onClick={ self.handleSelectNew.bind( self ) }>{ t('club_reservations.error.select_new_time') } &rarr;</a>

                </div>
            );
        } else {

        return (
            <div className="res">

                <div className="res-header">
                    <img alt="Anytime Fitness" className='header-image-mobile' src={ HeaderImageMobile } onClick={this.goToMainScreen.bind(this)} />
                    <img alt="Anytime Fitness" className='header-image-desktop' src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                    <LoggedInUser customClass={'fixed-middle-right'}></LoggedInUser>
                </div>
                <div className="res-subheader">
                    <h1>{ t('club_reservations.top.header') }</h1>
                </div>

                <div className="res-page">

                    <div className="res-gyminfo">
                        { (self.state.club.is_active && !self.state.club.is_temp_closed && !self.state.club.is_reservable) ?
                            <h1 style={{fontSize: '1.3em'}}>{ t('club_reservations.top.reservation_not_required') }</h1>
                            :
                            <h1>{ self.state.club.available_times_count } { t('club_reservations.top.available_times') }</h1>
                        }
                        <h2>{ self.state.club.name }</h2>
                        <div className="res-address">{ self.state.club.full_address }</div>
                        {
                            self.props.UserStore.isLogged() && self.props.UserStore.me.home_club_ident !== self.state.club_ident
                            && !self.props.UserStore.canAdminClub(self.state.club.ident) &&
                            <div className="res-guest">
                                <Trans i18nKey="club_reservations.top.res_guest">
                                    You can only reserve time 3 hours out as <span>{{ clubName }}</span> is not your home gym.
                                </Trans>
                            </div>
                        }
                        <Link className="res-return" to="/clubs">{ t('club_reservations.top.select_different_gym') }</Link>
                    </div>

                    { !(self.state.club.is_active && !self.state.club.is_temp_closed && !self.state.club.is_reservable) &&
                    <div className="res-container">
                        <div className="res-content">
                            { self.state.club.available_days.map( ( day, i ) =>
                                <div key={ i } className="res-day">
                                    <h3>
                                        { DateUtils.displayLongDateFromStr( day.ident ) }
                                        { ( self.state.resDates.indexOf( day.ident ) > -1 ) && (
                                            <span className="res-day-reserved">&#x2714;</span>
                                        )}
                                    </h3>
                                    <div className="res-day-times">
                                    { day.time_slots.map( ( timeSlot, j ) =>
                                        <div key={ j } className={ self.state.timeSlotIds.indexOf( timeSlot.id ) > -1 ? 'res-timeslot res-timeslot-reserved' : ( self.state.selectedTime === timeSlot.start_int ? 'res-timeslot res-timeslot-active' : 'res-timeslot' ) }>
                                            <a href="/" className="res-timeslot-select" data-day_ident={ day.ident } data-start_int={ timeSlot.start_int } data-timeslot_id={ timeSlot.id } onClick={ self.handleTimeClick.bind( self ) }>
                                                { timeSlot.is_senior && (
                                                    <span className="res-timeslot-label">{ t('global.senior') }</span>
                                                )}
                                                { ( self.state.timeSlotIds.indexOf( timeSlot.id ) > -1 ) && (
                                                    <span className="res-day-reserved">&#x2714;</span>
                                                )}
                                                { DateUtils.displayTimeFromStr( timeSlot.start_time ) }
                                            </a>
                                            <a href="/" className="res-timeslot-confirm" onClick={ self.handleConfirmClick.bind( self ) }>
                                                &#x2714;&nbsp;{ t('club_reservations.times.confirm') }
                                            </a>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>

                        { !this.props.UserStore.isLogged() && (
                            <div className="res-login">
                                <p>{ t('club_reservations.login.more_times_available') }</p>
                                <a className="res-loginbutton" onClick={ self.handleLoginClick.bind( self ) } href="/">{ t('club_reservations.login.login_for_full_availability') }</a>
                            </div>
                        )}
                    </div>
                    }
                </div>

                { self.state.showModalReserved && (
                    <div className="blanket" onClick={ self.closeModalReserved.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalReserved.bind( self ) }>X</a>
                                <h3>{ t('club_reservations.limit_modal.title') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-message">
                                    { t('club_reservations.limit_modal.message') }
                                </div>

                                <div className="modal-small-field">
                                    <Link className="modal-submit" to="/reservations">{ t('club_reservations.limit_modal.close') }</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        );
        }
    }
}

ClubReservations = inject('UserStore', 'ModalStore')(observer(ClubReservations));
export default withTranslation()( ClubReservations );
