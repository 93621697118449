import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import './Modal.scss'


class Modal extends Component {

    render() {
        const { isOpen  } = this.props.ModalStore;
        const styles = {
            modalVisibility: () => {
                if (isOpen) {
                    return {
                        display: 'flex',
                    }
                }else{
                    return {
                        display: 'none',
                    }
                }
            },
        }
        return(
            <div className="app-modal"
                style = {styles.modalVisibility()}
            >
                <div className='icon-container'>
                    <div className='loading-circle'>
                        <div className='circle'></div>
                    </div>
                    <AccessTimeIcon style={{color: 'white'}} fontSize='large'></AccessTimeIcon>
                </div>
            </div>
        )
    }
}

Modal  = inject('ModalStore')(observer(Modal))
export default Modal;




