import { observable, action,  decorate } from 'mobx';

class ModalStore {
    //observables
    isOpen = false;
    isOpenInComponent = false;

    //setSection
    openModal(){
        this.isOpen = true
    }
    closeModal(){
        this.isOpen = false
    }

    openInComponent() {
        this.isOpenInComponent = true;
    }

    closeInComponent() {
        this.isOpenInComponent = false;
    }
}

decorate(ModalStore, {
    isOpen: observable,
    isOpenInComponent: observable,
    openModal: action,
    closeModal: action,
    openInComponent: action,
    closeInComponent: action
});

export default new ModalStore();

