import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Admin.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import { withTranslation, Trans } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import DateUtils from '../../Utils/DateUtils';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import LoggedInUser from '../Account/LoggedInUser';
import i18n from "i18next";
import InComponentModal from '../Modal/InComponentModal';
import TimePicker from '../TimePicker/TimePicker';

class AdminClubTimeSlots extends Component {

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    state = {
        loading: true,
        dateStr: '',
        club_ident: null,
        club: null,
        filledReservations: false,
        months: [],
        reservations: [],

        showModalAdd: false,
        start_int: null,
        workout_minutes: null,
        cleaning_minutes: null,
        is_senior: false,

        showModalEdit: false,
        isSaving: false,
        editTimeSlotId: null,
        editTimeSlotInt: null,
        advance_spots: null,
        walkin_spots: null,
        staff_spots: null
    };

    async componentDidMount() {
        if ( !this.props.UserStore.isLogged() ) return this.props.UserStore.forceLogin( true );
        const { club_ident } = this.props.match.params;
        let club = await window.muuv.get( '/clubs/' + club_ident );
        if ( club.error ) return alert( club.error );
        let dateStr;
        if ( !this.props.ReservationsStore.selectedDate ) {
            dateStr = DateUtils.inputDate( new Date(), club.timezone );
        } else {
            dateStr = DateUtils.inputDate( this.props.ReservationsStore.selectedDate );
        }
        this.setState({
            club_ident: club_ident,
            club: club,
            dateStr: dateStr
        }, this.getClubData.bind( this ) );
    }

    async getClubData() {
        let club = await window.muuv.get( '/clubs/' + this.state.club_ident + '?date=' + this.state.dateStr );
        // Make sure I can edit this club.
        if ( !club.is_editable && !this.props.UserStore.isAdmin() ) {
            return this.props.history.push( '/clubs' );
        }
        const { t } = this.props;
        club.full_address = window.muuv.getFullAddress( club );
        document.title = t('admin.club_timeslots.document_title') + club.name;
        const en = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( en('admin.club_timeslots.document_title') + club.name);
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('admin.club_timeslots.document_title') + club.name,
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        this.setState({
            club,
            loading: ( !this.state.filledReservations )
        }, this.fillReservations.bind( this ) );
    }

    async fillReservations() {
        if ( this.state.filledReservations ) {
            this.props.ModalStore.closeModal();
            this.props.ModalStore.closeInComponent();
            return;
        }

        let months = this.fillMonths();

        // Get all future reservations, so we can apply them to the time slots.
        let reservationsList = await window.muuv.get( '/reservations?clubs=' + this.state.club_ident + '&start_gt=' + ( DateUtils.beginningOfDayInt( new Date().getTime(), this.state.club.timezone ) - 1 ) + '&per_page=1000' );
        let reservationsPerDay = await window.muuv.get(`/clubs/${ this.state.club_ident }/reservations/daily_counts`);

        months.forEach( month => {
            month.days.forEach( day => {
                day.reservations = reservationsPerDay[day.ident];
                day.reservations = day.reservations || 0;
            });
        });

        this.setState({
            loading: false,
            filledReservations: true,
            reservations: reservationsList.results,
            months
        });

        const initialDate = document.getElementById('date-' + this.state.dateStr);
        if (this.props.ReservationsStore.selectedDate && this.props.ReservationsStore.selectedDate.getTime() > new Date().getTime() && initialDate) {
            initialDate.scrollIntoView();
        }
        this.props.ModalStore.closeModal();
        this.props.ModalStore.closeInComponent();
    }

    fillMonths() {
        let now = new Date();
        let dateStr = DateUtils.inputDate( now, this.state.club.timezone );
        let arr = dateStr.split('-');
        let year = parseInt( arr[0], 10 );
        let month = (parseInt( arr[1], 10 )-1);
        let day = parseInt( arr[2], 10 );
        let currentMonth = 0;
        let months = [ ];
        months.push({
            year: year,
            month: month,
            name: new Date( year, month, day ).toLocaleDateString( this.props.UserStore.lang, { year: 'numeric', month: 'long' }),
            days: []
        });

        for (let i=0;i<60;i++) {
            if ( i !== 0 ) {
                now.setDate( now.getDate() + 1 );
            }
            dateStr = DateUtils.inputDate( now, this.state.club.timezone );
            arr = dateStr.split('-');
            year = parseInt( arr[0], 10 );
            month = (parseInt( arr[1], 10 )-1);
            day = parseInt( arr[2], 10 );
            if ( year !== months[ currentMonth ].year || month !== months[ currentMonth ].month ) {
                // Start a new month.
                currentMonth++;
                months.push({
                    year: year,
                    month: month,
                    name: new Date( year, month, day ).toLocaleDateString( this.props.UserStore.lang, { year: 'numeric', month: 'long' }),
                    days: []
                });
            }
            months[ currentMonth ].days.push({
                ident: DateUtils.inputDate( now, this.state.club.timezone ),
                weekday: new Date( year, month, day ).toLocaleDateString( this.props.UserStore.lang, { weekday: 'short' }),
                num: new Date( year, month, day ).toLocaleDateString( this.props.UserStore.lang, { day: 'numeric' }),
                reservations: 0
            });
        }
        return months;
    }

    handleDayClick(e) {
        e.preventDefault();
        if (this.props.ModalStore.isOpenInComponent) return;
        this.props.ModalStore.openInComponent();
        let dateStr = e.currentTarget.getAttribute( 'data-date' );
        let date = DateUtils.dateFromStr( dateStr );
        this.props.ReservationsStore.selectedDate = date;
        this.setState({ dateStr }, this.getClubData.bind( this ) );
    }

    // ========================
    // Add Modal

    handleAddClick(e) {
        e.preventDefault();
        let startInt = null;
        if ( this.state.club.time_slots.length > 0 ) {
            startInt = this.state.club.time_slots[ this.state.club.time_slots.length - 1 ].stop_int;
        }
        this.setState({
            showModalAdd:       true,
            start_int:          startInt,
            workout_minutes:    this.state.club.workout_minutes,
            cleaning_minutes:   this.state.club.cleaning_minutes,
            staff_spots:        this.state.club.staff_spots_per_time_slot,
            walkin_spots:       this.state.club.walkin_spots_per_time_slot,
            advance_spots:      this.state.club.advance_spots_per_time_slot,
            is_senior:          false
        });
    }

    async handleAddSubmit(e) {
        e.preventDefault();
        if ( this.state.isSaving ) return;
        this.setState({ isSaving: true });

        let slot = await window.muuv.post( '/time_slots', {
            club_ident:         this.state.club.ident,
            start_int:          this.state.start_int,
            workout_minutes:    this.state.workout_minutes,
            cleaning_minutes:   this.state.cleaning_minutes,
            staff_spots:        this.state.staff_spots,
            walkin_spots:       this.state.walkin_spots,
            advance_spots:      this.state.advance_spots,
            is_senior:          this.state.is_senior
        });
        if ( slot.error ) {
            alert( slot.error );
        }
        this.setState({
            showModalAdd: false,
            isSaving: false,
            start_int: null,
            workout_minutes: null,
            cleaning_minutes: null,
            is_senior: false,
            staff_spots: null,
            walkin_spots: null,
            advance_spots: null
        });
        await this.getClubData();
        this.closeModalAdd();
    }

    handleInputChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleInputIntegerChange(e) {
        let obj = {};
        obj[ e.target.name ] = parseInt( e.target.value, 10 );
        this.setState( obj );
    }

    handleCheckboxClick() {
        this.setState({ is_senior: !this.state.is_senior });
    }

// TODO: Get the occupied hours and remove them from the list.
    getValidTimes( key ) {
        let self = this;
        let usedTimes = [];
        this.state.club.time_slots.forEach( timeSlot => {
            let foundTime = false;
            //  Going to make the assumption that timeSlots are in order of start_int.
            usedTimes.forEach( usedTime => {
                // Does this overlap or connect with this time block?
                if ( timeSlot.start_int <= ( usedTime.start_int + 1 ) ) {
                    // If so, then merge it in.
                    foundTime = true;
                    // Check to make sure this timeslot ends after the current time block.
                    if ( timeSlot.stop_int > usedTime.stop_int ) {
                        usedTime.stop_int = timeSlot.stop_int;
                    }
                    // If it doesn't for some reason, just skip this timeslot.
                    // This should never happen.
                }
                // If this time is after the current time slot ends, there's a gap.
                // After it gets done looping, then it will add a new time block to the array.
            });
            if ( !foundTime ) {
                usedTimes.push({ start_int: timeSlot.start_int, stop_int: timeSlot.stop_int });
            }
        });
        DateUtils.timeValues().forEach( timeStr => {
            let timestamp = DateUtils.getTimestampFromDateTimeStr( this.state.dateStr, timeStr, this.state.club.timezone );
            let validTime = true;
            usedTimes.forEach( usedTime => {
                // It can be equal to the stop time of a time block.
                if ( timestamp >= usedTime.start_int && timestamp < usedTime.stop_int ) {
                    validTime = false;
                }
            });
            if ( !validTime ) return;
        });
        return (
            <>
                <TimePicker timeFraction={5} timezone={this.state.club.timezone} usedTimes={usedTimes} dateStr={this.state.dateStr}
                    handleOnChange={self.handleTimePickerChange.bind( self )}>
                </TimePicker>
            </>
        );
    }

    handleTimePickerChange(timestamp) {
        let obj = {};
        obj['start_int'] = timestamp;
        this.setState( obj );
    }

    getWorkoutTimes() {
        let self = this;
        let str = '';
        [ 30, 45, 60, 75, 90, 105, 120 ].forEach( min => {
            str += '<option value="' + min + '"' + ( self.state.workout_minutes === min ? ' selected' : '' ) + '>' + min +' min</option>';
        });
        return (
            <select id="workout_minutes" name="workout_minutes" onChange={ self.handleInputIntegerChange.bind( self ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    getCleaningTimes() {
        let self = this;
        let str = '';
        [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60 ].forEach( min => {
            str += '<option value="' + min + '"' + ( self.state.cleaning_minutes === min ? ' selected' : '' ) + '>' + min +' min</option>';
        });
        return (
            <select id="cleaning_minutes" name="cleaning_minutes" onChange={ self.handleInputIntegerChange.bind( self ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    closeModalAdd(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' ) ) return;
        }
        this.setState({
            showModalAdd: false,
            isSaving: false,
            start_int: null,
            workout_minutes: null,
            cleaning_minutes: null,
            is_senior: false,
            staff_spots: null,
            walkin_spots: null,
            advance_spots: null
        });
    }

    // ========================
    // Edit Modal

    handleEditClick(e) {
        e.preventDefault();
        this.setState({
            showModalEdit: true,
            editTimeSlotId: e.currentTarget.getAttribute( 'data-timeslot_id' ),
            editTimeSlotInt: parseInt( e.currentTarget.getAttribute( 'data-timeslot_int' ), 10 ),
            advance_spots: parseInt( e.currentTarget.getAttribute( 'data-advance_spots' ), 10 ),
            walkin_spots: parseInt( e.currentTarget.getAttribute( 'data-walkin_spots' ), 10 ),
            staff_spots: parseInt( e.currentTarget.getAttribute( 'data-staff_spots' ), 10 )
        });
    }

    async handleEditSubmit(e) {
        e.preventDefault();
        if ( this.state.isSaving ) return;
        this.setState({ isSaving: true });

        let slot = await window.muuv.put( '/time_slots/' + this.state.editTimeSlotId, {
            club_ident: this.state.club.ident,
            start_int: this.state.editTimeSlotInt,
            advance_spots: this.state.advance_spots,
            walkin_spots: this.state.walkin_spots,
            staff_spots: this.state.staff_spots
        });
        if ( slot.error ) {
            alert( slot.error );
        }
        this.setState({
            showModalEdit: false,
            isSaving: false,
            editTimeSlotId: null,
            editTimeSlotInt: null,
            advance_spots: null,
            walkin_spots: null,
            staff_spots: null
        });
        await this.getClubData();
        this.closeModalEdit();
    }

    closeModalEdit(e) {
        if (e) {
            e.preventDefault();
            if ( !e.target || ( e.target.className !== 'blanket' && e.target.className !== 'modal-close' ) ) return;
        }
        this.setState({
            showModalEdit: false,
            isSaving: false,
            editTimeSlotId: null,
            editTimeSlotInt: null,
            advance_spots: null,
            walkin_spots: null,
            staff_spots: null
        });
    }

    getSpotsSelect( key ) {
        let self = this;
        let str = '';
        for (let i=0;i<101;i++) {
            str += '<option value="' + i + '"' + ( self.state[ key ] === i ? ' selected' : '' ) + '>' + i +'</option>';
        }
        return (
            <select id={ key } name={ key } onChange={ self.handleInputIntegerChange.bind( self ) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    async handleDeleteClick(e) {
        e.preventDefault();
        const { t } = this.props;
        if ( window.confirm( t('admin.club_timeslots.confirm_delete_timeslot') ) ) {
            let slot = await window.muuv.delete( '/time_slots/' + this.state.editTimeSlotId + '?club_ident=' + this.state.club.ident + '&start_int=' + this.state.editTimeSlotInt );
            this.sendDeleteEventToAnalytics(this.state.editTimeSlotId);
            if ( slot.error ) {
                alert( slot.error );
            }
            await this.getClubData();
            this.closeModalEdit();
        }
    }

    sendDeleteEventToAnalytics(timeslotId) {
        const timeSlot =  this.state.club.time_slots.find((timeSlot) => {
            return timeslotId === timeSlot.id;
        });
        if (timeSlot) {
            const evt = {
                id: timeSlot.id,
                timeslot_date: timeSlot.date,
                duration: timeSlot.total_minutes,
                timeslot_start_time: timeSlot.start_time
            };
            window.muuv.analytics.logEvent('timeslot_admin_deleted', evt);
        }
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        if ( self.state.loading ) return '';

        return (
            <div className="admin">

                <div className="admin-header">
                    <img alt="Anytime Fitness" src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)} />
                </div>
                <div className="admin-subheader">
                    <h1>
                        <Link className="admin-returnto" to={ `/admin/clubs/${ self.state.club.ident }` }>&larr; { t('admin.reservations') }</Link>
                        { t('admin.club_timeslots.club_timeslots') }
                    </h1>
                    <Grid item xs={4} md={2}>
                        <LoggedInUser></LoggedInUser>
                    </Grid>
                </div>

                <div className="admin-page">
                    <div className="admin-sidebar">
                        <div className="admin-sidebar-content">
                            <h2>
                                { self.state.club.name }
                                <div className="admin-club-address">
                                    { self.state.club.full_address }
                                </div>
                            </h2>
                            <div className="admin-sidebar-section">
                                { self.state.months.map( ( month, i ) =>
                                    <div key={ i } className="admin-sidebar-month">
                                        <h3>{ month.name }</h3>
                                        { month.days.map( ( day, j ) => {
                                            let dayReservations = day.reservations;
                                            return (
                                                <a key={ j } id={'date-' + day.ident} href="/" data-date={ day.ident } className={ day.ident === self.state.dateStr ? 'admin-times-day-row admin-times-day-row-selected' : 'admin-times-day-row' } onClick={ self.handleDayClick.bind( self ) }>
                                                    <span className="admin-times-date"><i>{ day.weekday }</i> <b>{ day.num }</b></span>
                                                    <span className={ day.reservations === 0 ? 'admin-times-rescount admin-times-rescount-none' : 'admin-times-rescount' }>
                                                        <Trans i18nKey="admin.club_timeslots.number_reservations" count={ dayReservations }>
                                                            <b>{{ dayReservations }}</b> Reservations
                                                        </Trans>
                                                    </span>
                                                </a>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="admin-container">
                        <InComponentModal className='admin-in-modal'></InComponentModal>
                        <div className="admin-content-header">
                            <div className="admin-times-header">
                                <div className="admin-times-td admin-times-td-large">{ t('admin.time') }</div>
                                <div className="admin-times-td admin-times-td-large">{ t('admin.reservations') }</div>
                                <div className="admin-times-td">{ t('admin.advance') }</div>
                                <div className="admin-times-td">{ t('admin.walkin') }</div>
                                <div className="admin-times-td">{ t('admin.staff') }</div>
                                <div className="admin-times-td"></div>
                            </div>
                        </div>
                        <div className="admin-content">
                            { self.state.club.time_slots.map( ( timeSlot, i ) =>
                                <div key={ i } className="admin-times-row">
                                    <div className="admin-times-td admin-times-td-large">
                                        { DateUtils.displayTimeFromInt( timeSlot.start_int, timeSlot.timezone ) } &ndash; { DateUtils.displayTimeFromInt( timeSlot.stop_int, timeSlot.timezone ) }
                                        { timeSlot.is_senior && (
                                            <span className="admin-timeslot-senior">{ t('global.senior') }</span>
                                        )}
                                    </div>
                                    <div className="admin-times-td admin-times-td-large">{ ( timeSlot.advance_spots + timeSlot.walkin_spots ) - ( timeSlot.advance_spots_open + timeSlot.walkin_spots_open ) }</div>
                                    <div className="admin-times-td">{ timeSlot.advance_spots - timeSlot.advance_spots_open }/{ timeSlot.advance_spots }</div>
                                    <div className="admin-times-td">{ timeSlot.walkin_spots - timeSlot.walkin_spots_open }/{ timeSlot.walkin_spots }</div>
                                    <div className="admin-times-td">{ timeSlot.staff_spots }</div>
                                    <div className="admin-times-td">
                                            <a href="/"
                                                className="admin-times-edit"
                                                onClick={ self.handleEditClick.bind( self ) }
                                                data-timeslot_id={ timeSlot.id }
                                                data-timeslot_int={ timeSlot.start_int }
                                                data-advance_spots={ timeSlot.advance_spots }
                                                data-walkin_spots={ timeSlot.walkin_spots }
                                                data-staff_spots={ timeSlot.staff_spots }>{ t('global.edit') }</a>
                                    </div>
                                </div>
                            )}
                            <a href="/" className="admin-times-add" onClick={ self.handleAddClick.bind( self ) }>
                                { t('admin.club_timeslots.add_timeslot') }
                            </a>
                        </div>
                    </div>

                </div>

                { self.state.showModalEdit && (
                    <div className="blanket" onClick={ self.closeModalEdit.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalEdit.bind( self ) }>X</a>
                                <h3>{ t('admin.club_timeslots.edit_timeslot') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.advance_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.advance_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'advance_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.walkin_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.walkin_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'walkin_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.staff_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.staff_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'staff_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.remove_timeslot') }</h4>
                                        <p>{ t('admin.club_timeslots.remove_timeslot_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        <a href="/" className="modal-field-delete" onClick={ self.handleDeleteClick.bind( self ) }><img src="/img/trash-can.svg" alt={ t('global.delete') } /></a>
                                    </div>
                                </div>

                                <div className="modal-small-field">
                                    <a href="/" className="modal-submit" onClick={ self.handleEditSubmit.bind( self ) } >{ self.state.isSaving ? t('global.saving') + '...' : t('global.save') }</a>
                                </div>
                            </div>

                        </div>
                    </div>
                )}

                { self.state.showModalAdd && (
                    <div className="blanket" onClick={ self.closeModalAdd.bind( self ) }>
                        <div className="modal modal-small">
                            <div className="modal-header">
                                <a href="/" className="modal-close" onClick={ self.closeModalAdd.bind( self ) }>X</a>
                                <h3>{ t('admin.club_timeslots.add_timeslot') }</h3>
                            </div>
                            <div className="modal-form">

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.start_hours') }</h4>
                                        <p>{ t('admin.club_timeslots.start_hours_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        <span className="modal-senior-fields">
                                            { self.getValidTimes( 'start_int' ) }
                                        </span>
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_reservations.reservation_duration') }</h4>
                                        <p>{ t('admin.club_reservations.reservation_duration_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getWorkoutTimes() }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_reservations.cleaning_duration') }</h4>
                                        <p>{ t('admin.club_reservations.cleaning_duration_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getCleaningTimes() }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.advance_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.advance_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'advance_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.walkin_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.walkin_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'walkin_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_timeslots.staff_reservation_spots') }</h4>
                                        <p>{ t('admin.club_timeslots.staff_reservation_spots_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        { self.getSpotsSelect( 'staff_spots' ) }
                                    </div>
                                </div>

                                <div className="modal-field">
                                    <div className="modal-field-label">
                                        <h4>{ t('admin.club_reservations.senior_hours') }</h4>
                                        <p>{ t('admin.club_reservations.senior_hours_explain') }</p>
                                    </div>
                                    <div className="modal-field-input">
                                        <FormControlLabel className="modal-field-senior-label"
                                            onClick={self.handleCheckboxClick.bind( self )}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={self.state.is_senior}
                                                />
                                            }
                                            label={ t('global.enabled') }
                                            labelPlacement="start"
                                        />
                                    </div>
                                </div>

                                <div className="modal-small-field">
                                    <a href="/" className="modal-submit" onClick={ self.handleAddSubmit.bind( self ) } >{ self.state.isSaving ? t('global.saving') + '...' : t('global.save') }</a>
                                </div>
                            </div>

                        </div>
                    </div>
                )}

            </div>
        );
    }
}

AdminClubTimeSlots = inject('UserStore', 'ModalStore', 'ReservationsStore')(observer(AdminClubTimeSlots));
export default withTranslation()( AdminClubTimeSlots );
