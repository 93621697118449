import React, { Component } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import './ClubList.scss';
import { Grid, Button } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import DateUtils from '../../Utils/DateUtils';

class ClubList extends Component {

    units = '';

    constructor(params) {
        super(params);
        this.units = this.props.UserStore.units;
    }


    selectLocation(club) {
        if (this.props.ClubListingsStore.highlightedClubId !== club.id) {
            this.props.ClubFinderStore.popupInfo = club;
            this.props.ClubListingsStore.highlightedClubId = club.id;
            var {viewport} = this.props.ClubFinderStore;
            var lon = club.lng;
            var lat = club.lat;
            var new_viewport = {
                lat: lat,
                lng: lon
            }
            this.props.ClubFinderStore.viewport = { ...viewport, ...new_viewport }
            this.props.ClubListingsStore.selectSelectedClub(club);
        } else {
            this.props.ClubFinderStore.popupInfo = null;
            this.props.ClubListingsStore.selectSelectedClub(null);
        }
    }

    selectClub(club, event) {
        this.sendSelectEventToAnalytics(club)
        this.props.ClubListingsStore.highlightedClubId = '';
        this.props.history.push(`/clubs/${club.ident}`);
        event.stopPropagation();
    }

    sendSelectEventToAnalytics(club) {
        const evt = {
            "club_country": club.address_country,
            "club_id": club.id,
            "club_state": club.address_state,
            "club_tz": club.timezone,
            "club_ident": club.ident,
            "club_city": club.address_city,
            "club_name": club.name,
            "club_postal_code": club.address_zip
        };
        window.muuv.analytics.logEvent('club_selected', evt);
    }

    getOpenSpot(club) {
        let nextSlot = null;
        let openSpotsQty = 0;
        let isMember = this.props.UserStore.me.home_club_ident === club.ident;
        if ( this.props.UserStore.canAdminClub( club.ident ) ) {
            isMember = true;
        }
        club.time_slots.forEach( timeSlot => {
            if ( !this.props.UserStore.isTimeSlotValid( timeSlot ) || (!isMember && openSpotsQty === 3)) return;
            nextSlot = nextSlot || timeSlot;
            openSpotsQty += 1;
        });
        return {openSpots: openSpotsQty, nextSlot: nextSlot};
    }

    focusElement(elementId) {
        const element = document.getElementById(elementId);
        const parent = document.getElementById('club-list');
        if (element && parent) {
            const bounding = element.getBoundingClientRect();
            if (!(bounding.top >= parent.offsetTop &&
                bounding.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 10))) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }
    }

    getAvailability(club, isSelected) {
        const { t } = this.props;
        let timeSlot = this.getOpenSpot(club);
        let availableSpots;
        let select;
        let available = false;
        let nextDate;

        switch(club.status_id) {
            case 1: {
                availableSpots = <div>{t('map.list.coming_soon')}</div>
                break;
            }
            case 2: {
                availableSpots = <div>{t('map.list.pre_sale')}</div>
                break;
            }
            case 3: {
                if (club.is_temp_closed) {
                    availableSpots = <div>{t('map.list.temporarily_closed')}</div>
                } else if (!club.is_reservable) {
                    availableSpots = <div>{t('map.list.open')}</div>
                    select = <div className='option-disabled'>{t('map.list.no_reservation_needed')}</div>;
                } else if (timeSlot.openSpots > 0) {
                    available = true;
                    nextDate = DateUtils.getDateOrToday(timeSlot.nextSlot.date, timeSlot.nextSlot.timezone);
                    nextDate = nextDate === 'Today' ? t('map.list.today') : nextDate;
                    availableSpots = <div>{timeSlot.openSpots} { t('map.list.available') }</div>;
                    select = <Button onClick={this.selectClub.bind(this, club)} className={`select-button ${isSelected ? 'highlighted' : ''}`} style={{padding: '3px 12px 0'}}
                                endIcon={<ArrowRightAltIcon style={{ fontSize: 30 }}/>}>
                                { t('map.list.select') }
                            </Button>;
                } else {
                    availableSpots = <div>0 { t('map.list.available') }</div>;
                    select = <div className='option-disabled'>{t('map.list.unable_to_reserve')}</div>
                }
                break;
            }
            default: {
                console.log("Unknown status");
            }
        }
        return (
            <Grid item xs={12} className='availability-container'>
                <div className={`availability ${ isSelected ? 'available' : ''}`}>
                    { availableSpots }
                    { available && timeSlot.nextSlot &&
                        <div className='next-slot'>({ t('map.list.next') }: {nextDate} @ { DateUtils.displayTimeFromStr( timeSlot.nextSlot.start_time ) })
                        </div>
                    }
                </div>
                { select }
            </Grid>

        );
    }

    clubItem(club, highlightedClubId) {
        const { t } = this.props;
        const isSelected = club.id === highlightedClubId;
        if (isSelected) {
            this.focusElement('club-' + club.id);
        }
        return(
            <Grid item xs={12} id={'club-' + club.id} key={'club-' + club.id} className='club-info-container' onClick={this.selectLocation.bind(this, club)}>
                <Grid item xs={12} className='club-info'>
                    <Grid item xs={10}>
                        <div className='club-name'>{club.name}</div>
                        <div className='club-address'>{club.address_line1}</div>
                        <div className='club-address'>{club.address_state} {club.address_zip}</div>
                    </Grid>
                    <Grid item xs={2} className='distance-container'>
                        <div className='distance'>
                            {club.distance}
                        </div>
                        <div>
                            { t('map.list.' + this.units) }
                        </div>
                    </Grid>
                </Grid>
                {
                    this.getAvailability(club, isSelected)
                }
            </Grid>
        );
    }

    changeUnits() {
        this.props.ClubListingsStore.changeDistanceUnits(this.units);
    }

    render() {
        const {clubsInBoundaries, highlightedClubId} = this.props.ClubListingsStore;
        let clubItems = [];
        let { units } = this.props.UserStore;
        if (this.units !== this.props.UserStore.units) {
            // if the unit of measure changed recalculate the distances
            this.units = units;
            this.changeUnits();
        }
        const getClubsQuantity = () => {
            let clubsCount = clubsInBoundaries.length;
            return (
                <div className='search-results'>
                    <Trans i18nKey="map.list.found_near_you" count={ clubsCount }>
                        We found these near you (<span>{{ clubsCount }}</span> results)
                    </Trans>
                </div>
            );
        }
        const getClubs = () => {
            clubsInBoundaries.forEach(club => {
                clubItems.push(
                    this.clubItem(club, highlightedClubId)
                );
            });
            return clubItems;
        };
        return (
            <Grid item xs={12} id='club-list' className='club-list'>
                {getClubsQuantity()}
                {getClubs()}
            </Grid>
        );
    }
}

ClubList = inject('ClubListingsStore', 'ClubFinderStore', 'UserStore')(observer(ClubList));
export default withTranslation()( withRouter(ClubList) );
