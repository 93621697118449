import { observable, action,  decorate } from 'mobx';
import Agent from '../../Api/Agent';
import UserStore from '../UserStore';

class ReservationsStore {

    selectedDate;

    reservations = [];
    getReservationsForUser() {
        if (UserStore.isLogged()) {
            const timestamp = new Date().getTime();
            Agent.Reservations.reservations(timestamp).then((data) => {
                this.reservations = data.results;
            });
        }
    }
}

decorate(ReservationsStore, {
    selectedDate: observable,
    reservations: observable,
    getReservationsForUser: action
});

export default new ReservationsStore();
