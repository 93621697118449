import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';
import './ClubSearch.scss';
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { withTranslation, Trans } from 'react-i18next';

class ClubSearch extends Component {

    lastSearch = new Date().getTime();

    componentDidMount() {
        this.props.ReservationsStore.getReservationsForUser();
        this.refreshGeocoder();
    }

    refreshGeocoder() {
        const {MAPBOX_TOKEN} = this.props.ClubFinderStore;
        mapboxgl.accessToken = MAPBOX_TOKEN;
        const { t } = this.props;
        const language = this.props.UserStore.lang.split('-')[0];
        let geocoder = new MapboxGeocoder({
            container: this.geocoderRef,
            placeholder: t('map.header.search'),
            accessToken: MAPBOX_TOKEN,
            mapboxgl: mapboxgl,
            types: 'place,country,region,district,postcode,locality',
            language: language
            });
        if (this.props.ClubFinderStore.mapRef) {
            geocoder.on('result', (result) => {
                this.sendSearchEventToAnalytics(result.result.place_name);
                document.getElementById('geocoder-input').getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].blur();
            })
            document.getElementById('geocoder-input').innerHTML = '';

            // Add delay to MapboxGeocoder lib on keydown event to prevent unnecessary api calls
            var geoOnKeyDown = geocoder._onKeyDown.bind(geocoder);
            geocoder._onKeyDown = (e) => {
                this.lastSearch = new Date().getTime();
                setTimeout(() => {
                    const currentTime = new Date().getTime();
                    if (currentTime - this.lastSearch >= 800) {
                        geoOnKeyDown(e);
                    }
                }, 800);
            }
            document.getElementById('geocoder-input').appendChild(geocoder.onAdd(this.props.ClubFinderStore.mapRef));
            // Add delay to MapboxGeocoder eventManager on key event to prevent unnecessary api calls
            const geoKeyEvent = geocoder.eventManager.keyevent.bind(geocoder.eventManager);
            geocoder.eventManager.keyevent = (e) => {
                setTimeout( () => {
                    const currentTime = new Date().getTime();
                    if (currentTime - this.lastSearch >= 800) {
                        geoKeyEvent(e, geocoder);
                    }
                }, 800);
            }
        }
    }

    sendSearchEventToAnalytics(searchTerm) {
        const evt = {
            "search_term": searchTerm
        };
        window.muuv.analytics.logEvent('search', evt);
    }

    existingReservations(existingReservations) {
        const { t } = this.props;
        return (
            <Grid item xs={12} className='existing-reservations'>
                <span>
                    <Trans i18nKey="map.header.you_have_existing" count={ existingReservations }>
                        You have <span className='reservations-qty'>{{ existingReservations }}</span> existing reservations.
                    </Trans>
                </span>
                <Link to='/reservations' className='modify'>
                    { t('map.header.modify') }
                </Link>
            </Grid>
        );
    }

    searchComponent() {
        return (
            <Grid item xs={12} className='search-component'>
                <div id='geocoder-input'>
                </div>
                <SearchIcon className='search-icon'/>
            </Grid>
        );
    }

    render() {
        let {reservations} = this.props.ReservationsStore;
        let {lang} = this.props.UserStore;
        if (lang && document.getElementById('geocoder-input')) {
            this.refreshGeocoder();
        }
        return (
            <Grid item xs={12} className={`club-search ${reservations.length === 0 ? 'no-reservations': ''}`}>
                {this.existingReservations(reservations.length)}
                {this.searchComponent()}
            </Grid>
        );
    }
}

ClubSearch = inject('ClubFinderStore', 'ReservationsStore', 'UserStore')(observer(ClubSearch));
export default withTranslation()( ClubSearch );
