import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Admin.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import { withTranslation, Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import LoggedInUser from '../Account/LoggedInUser';
import { Grid } from '@material-ui/core';
import i18n from "i18next";

class AdminClubIndex extends Component {

    state = {
        clubs: [],
        loading: false,
        total: 0,
        totalPages: 1,
        page: 0,
        checkedRedirect: false,
        doneLoading: false,
        term: ''
    };

    constructor( props ) {
        super( props );
        this.container = React.createRef();
        this.props.ModalStore.openModal();
    }

    async componentDidMount() {
        let clubParam = window.muuv.param('club');
        if ( clubParam ) {
            return this.props.history.push( '/admin/clubs/' + clubParam );
        }
        if ( !this.props.UserStore.isLogged() ) return this.props.UserStore.forceLogin( true );
        const { t } = this.props;
        const en = i18n.getFixedT('en');
        document.title = t('admin.club_list.document_title');
        window.muuv.analytics.setCurrentScreen( en('admin.club_list.document_title') );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('admin.club_list.document_title'),
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        this.props.ReservationsStore.selectedDate = null;
        this.loadClubs( 1 );
    }

    async loadClubs( page ) {
        this.setState({ loading: true });
        let url = '/clubs?per_page=20&page=' + page
        if ( !this.props.UserStore.isCompanyAdmin() ) {
            url += '&is_editable=1';
        }
        if ( this.state.term ) {
            url += '&term=' + this.state.term;
        }
        let response = await window.muuv.get( url );
        // If this is the first time we're loading the data,
        // check to make sure we should be on this page.
        // No editable clubs, they are a normal user.
        if ( !this.state.checkedRedirect && response.total === 0 && !this.props.UserStore.isAdmin() ) {
            this.props.history.push( '/clubs' );
        // Only one editable club, send them to that club.
        } else if ( !this.state.checkedRedirect && response.total === 1 ) {
            this.props.history.push( '/admin/clubs/' + response.results[ 0 ].ident );
        } else {
            this.setState({
                checkedRedirect: true,
                loading: false,
                page: page,
                total: response.total,
                totalPages: response.total_pages,
                clubs: this.state.clubs.concat( response.results ),
                doneLoading: ( page >= response.total_pages )
            }, this.checkLoad );
        }
        this.props.ModalStore.closeModal();
    }

    resetSearch() {
        let self = this;
        this.setState({
            loading: true,
            total: 0,
            totalPages: 1,
            page: 0,
            clubs: [],
            doneLoading: false
        }, () => {
            self.loadClubs( 1 );
        });
    }

    async checkLoad() {
        let container = this.container.current;
        if ( !container || this.state.loading || this.state.doneLoading ) return;
        if ( container.offsetHeight + container.scrollTop + 50 < container.scrollHeight ) return;
        let newPage = this.state.page + 1;
        this.loadClubs( newPage );
    }

    handleScroll( e ) {
        this.checkLoad();
    }

    handleSubmit( e ) {
        e.preventDefault();
        this.resetSearch();
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        if ( !self.state.checkedRedirect ) return '';
        let totalClubs = self.state.total;

        return (
            <div className="admin">

                <div className="admin-header">
                    <img onClick={this.goToMainScreen.bind(this)} alt="Anytime Fitness" src={ ReservationsHeaderImage } />
                </div>
                <div className="admin-subheader">
                    <h1>{ t('admin.club_list.header') }</h1>
                    <Grid item xs={4} md={2}>
                        <LoggedInUser></LoggedInUser>
                    </Grid>
                </div>

                <div className="admin-page">
                    <div className="admin-sidebar">
                        <div className="admin-sidebar-content">
                            <h2>
                                <Trans i18nKey="admin.club_list.showing_clubs" count={ totalClubs }>
                                    Showing <b>{{ totalClubs }}</b> Clubs
                                </Trans>
                            </h2>

                            <div className="admin-sidebar-section">
                                <div className="admin-search">
                                    <form onSubmit={ self.handleSubmit.bind(this) }>
                                        <label htmlFor="term"><span>{ t('admin.club_list.search_text') }</span></label>
                                        <input className="admin-search-text" type="text" name="term" id="term" required value={ this.state.term } onChange={ this.handleChange.bind(this) } />
                                    </form>
                                    <a className="admin-search-button" href="/" onClick={ this.handleSubmit.bind(this) }><img src="/img/search.svg" alt={ t('global.search') } /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="admin-container">
                        <div className="admin-content-header">
                            <div className="admin-th admin-td-20">{ t('admin.club_list.club_id') }</div>
                            <div className="admin-th admin-td-45">{ t('admin.club_list.name') }</div>
                            <div className="admin-th admin-td-20">{ t('admin.club_list.booking_status') }</div>
                        </div>
                        <div className="admin-content" onScroll={ self.handleScroll.bind( self ) } ref={ self.container }>
                            { self.state.clubs.map( ( club, i ) =>
                                <div key={ i } className="admin-content-tr">
                                    <div className="admin-td admin-td-20">{ club.ident }</div>
                                    <div className="admin-td admin-td-45">{ club.name }</div>
                                    <div className="admin-td admin-td-20"><span className={ club.is_reservable ? 'admin-status-open' : 'admin-status-closed' }>&bull;</span> { club.is_reservable ? t('admin.club_list.open') : t('admin.club_list.closed') }</div>
                                    <div className="admin-td admin-td-10"><Link className="admin-arrow" to={ `/admin/clubs/${ club.ident }` }>&rarr;</Link></div>
                                </div>
                            )}
                            { !self.state.doneLoading && (
                                <div className="admin-content-loading">{ t('global.loading') }...</div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

AdminClubIndex = inject('UserStore', 'ModalStore', 'ReservationsStore')(observer(AdminClubIndex));
export default withTranslation()( AdminClubIndex );
