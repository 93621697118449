import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Select, MenuItem } from "@material-ui/core";

import './LanguagePicker.scss';

class LanguagePicker extends Component {

    handleChange(event) {
        this.props.UserStore.setLanguage(event.target.value);
        this.props.ClubFinderStore.setMapLanguage();
    }

    render() {
        let {lang, languages}= this.props.UserStore;


        return (
            <div className='language-picker'>
                <Select value={lang}
                    className='picker-select'
                    onChange={this.handleChange.bind(this)}>
                    {
                        languages.map((language) => {
                            return <MenuItem key={language.text} value={language.value}>{language.text}</MenuItem>
                        })
                    }
                </Select>
            </div>
        );
    }
}

LanguagePicker = inject('UserStore','ClubFinderStore')(observer(LanguagePicker))
export default LanguagePicker;
