import moment from 'moment';
import 'moment-timezone';

import UserStore from '../Stores/UserStore';

class DateUtils {

    displayLongDateFromStr( dateStr ) {
        return this.dateFromStr( dateStr ).toLocaleDateString( UserStore.lang, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }

    displayLongDateFromStrEng( dateStr ) {
        return this.dateFromStr( dateStr ).toLocaleDateString( 'en', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }

    displayLongDateFromInt(dateInt, timezone) {
        return moment.tz(dateInt, timezone).toDate().toLocaleDateString(UserStore.lang, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }

    displaySmallDateFromInt(dateInt,timezone) {
        const dateToShow = this.getDateAsInTimezone(dateInt, timezone);
        return dateToShow.toLocaleDateString(UserStore.lang, {month: 'numeric', day: 'numeric'});
    }

    displayDateNoYearFromInt(dateInt, timezone) {
        const dateToShow = this.getDateAsInTimezone(dateInt, timezone);
        return dateToShow.toLocaleDateString(UserStore.lang, {weekday: 'long', month: 'short', day: 'numeric'});
    }

    getDateAsInTimezone(dateInt, timezone) {
        // Tricky way to display the same date that in timezone using toLocalDateString 
        const realMoment = moment.tz(dateInt, timezone);
        const dateToShow = this.dateFromStr(realMoment.format('YYYY-MM-DD'));
        return dateToShow;
    }

    displayTimeFromStr(timeStr) {
        // We'll be expecting a time like this: "5:00 AM"
        let now = new Date();
        let hour = parseInt(timeStr.split(':')[0], 10);
        let minutes = parseInt(timeStr.split(':')[1], 10);
        let ampm = timeStr.split(' ')[1];
        if (ampm === 'PM' && hour !== 12) hour += 12;
        if (ampm === 'AM' && hour === 12) hour = 0;
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes).toLocaleTimeString(UserStore.lang, {hour: 'numeric', minute: '2-digit'});
    }

    displayTimeFromInt(timeInt, timezone) {
        return this.displayTimeFromStr(moment.tz(timeInt, timezone).format('h:mm A'));
    }

    beginningOfDayInt(timeInt, timezone) {
        return moment.tz(timeInt, timezone).startOf( 'day' ).toDate().getTime();
    }

    inputDate(date, timezone) {
        if (timezone) {
            return moment.tz(date, timezone).format('YYYY-MM-DD');
        } else {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return year + '-' + ( month < 10 ? '0' : '' ) + month + '-' + ( day < 10 ? '0' : '' ) + day;
        }
    }

    dateFromStr( dateStr ) {
        let arr = dateStr.split('-');
        let year = parseInt( arr[0], 10 );
        let month = parseInt( arr[1], 10 );
        let day = parseInt( arr[2], 10 );
        return new Date( year, ( month - 1 ), day );
    }

    displaySmallWeekday(weekdayInt) {
        return new Date(2020, 3, (19 + weekdayInt)).toLocaleDateString(UserStore.lang, {weekday: 'short'});
    }

    getTimestampFromDateTimeStr( dateStr, timeStr, timezone ) {
        let thisDate = this.dateFromStr( dateStr );
        let hour = parseInt(timeStr.split(':')[0], 10);
        let minutes = parseInt(timeStr.split(':')[1], 10);
        let ampm = timeStr.split(' ')[1];
        if (ampm === 'PM' && hour !== 12) hour += 12;
        if (ampm === 'AM' && hour === 12) hour = 0;
        thisDate.setHours( hour, minutes );
        return thisDate.getTime();
    }

    getDateOrToday(dateStr) {
        // expects YYYY-MM-DD format, with MM from 1 to 12
        dateStr = dateStr.split('-')
        let date = new Date(parseInt(dateStr[0]), parseInt(dateStr[1]) - 1, parseInt(dateStr[2]));
        return this.isToday(date) ? 'Today' : date.toLocaleDateString(UserStore.lang, {month:'numeric', day: 'numeric'});
    }

    isToday(date) {
        const today = new Date();
        return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
    }

    timeValues() {
        return [
            "12:00 AM","12:15 AM","12:30 AM","12:45 AM","1:00 AM","1:15 AM","1:30 AM","1:45 AM","2:00 AM","2:15 AM","2:30 AM","2:45 AM","3:00 AM","3:15 AM","3:30 AM","3:45 AM","4:00 AM","4:15 AM","4:30 AM","4:45 AM","5:00 AM","5:15 AM","5:30 AM","5:45 AM","6:00 AM","6:15 AM","6:30 AM","6:45 AM","7:00 AM","7:15 AM","7:30 AM","7:45 AM","8:00 AM","8:15 AM","8:30 AM","8:45 AM","9:00 AM","9:15 AM","9:30 AM","9:45 AM","10:00 AM","10:15 AM","10:30 AM","10:45 AM","11:00 AM","11:15 AM","11:30 AM","11:45 AM","12:00 PM","12:15 PM","12:30 PM","12:45 PM","1:00 PM","1:15 PM","1:30 PM","1:45 PM","2:00 PM","2:15 PM","2:30 PM","2:45 PM","3:00 PM","3:15 PM","3:30 PM","3:45 PM","4:00 PM","4:15 PM","4:30 PM","4:45 PM","5:00 PM","5:15 PM","5:30 PM","5:45 PM","6:00 PM","6:15 PM","6:30 PM","6:45 PM","7:00 PM","7:15 PM","7:30 PM","7:45 PM","8:00 PM","8:15 PM","8:30 PM","8:45 PM","9:00 PM","9:15 PM","9:30 PM","9:45 PM","10:00 PM","10:15 PM","10:30 PM","10:45 PM","11:00 PM","11:15 PM","11:30 PM","11:45 PM","11:59 PM"
        ];
    }

    weekdays() {
        return ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
    }
}

export default new DateUtils();
