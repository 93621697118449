import { decorate, observable, action } from "mobx";
import i18n from './../../i18n';
import cookie from 'react-cookies'

import Agent from "../../Api/Agent";

class UserStore {

    me = {};
    lang = 'en';
    client_id = 'aba01f0e-c3b8-416d-81de-81996bfb5e3e';

    languages = [
        { value: 'de', text: 'de'},
        { value: 'en', text: 'en'},
        { value: 'en-US', text: 'en-US'},
        { value: 'en-AU', text: 'en-AU'},
        { value: 'en-IN', text: 'en-IN'},
        { value: 'en-GB', text: 'en-GB'},
        { value: 'es', text: 'es'},
        { value: 'es-ES', text: 'es-ES'},
        { value: 'es-419', text: 'es-LAT'},
        { value: 'es-MX', text: 'es-MX'},
        { value: 'es-CO', text: 'es-CO'},
        { value: 'fr', text: 'fr'},
        { value: 'it', text: 'it'},
        { value: 'id', text: 'id'},
        { value: 'ko', text: 'ko' },
        { value: 'ja', text: 'ja'},
        { value: 'nl', text: 'nl'},
        { value: 'th', text: 'th'},
        { value: 'zh-TW', text: 'zh-TW'}
    ];
    units;


    getMe() {
        return Agent.Users.getMe().then((json) => {
            this.me = json;
            this.inferLanguage();
            this.setUnits();
        }).then(() => {
            return this.loadLanguage();
        });
    }

    inferLanguage() {
        if ( 'selected_language' in this.me ) {
            this.lang = this.me.selected_language;
        } else if ( this.me.languages.length > 0 ) {
            this.lang = this.getValidLanguage( this.me.languages[0])
        }
    }

    getValidLanguage(language) {
        let validLanguage = this.languages.find((element) => {
            return element.value.toLowerCase() === language.toLowerCase();
        });

        if (!validLanguage) {
            validLanguage = this.languages.find((element) => {
                return element.value.toLowerCase().startsWith(language.split('-')[0].toLowerCase());
            })
        }

        return validLanguage ? validLanguage.value : 'en';
    }

    loadLanguage() {
        return i18n.changeLanguage(this.lang);
    }

    setLanguage(language) {
        if (language) {
            this.lang = language;
            Agent.Users.setLanguage(this.lang);
        }
        // set the units for the selected locale language
        this.setUnits();
        this.loadLanguage();
    }

    switchUnits() {
        if (this.units === 'miles') {
            this.units = 'kilometers';
        } else {
            this.units = 'miles';
        }
    }

    setUnits() {
        const parts = this.lang.split('-');
        if (parts.length > 1 && parts[1] === 'US') {
            this.units = 'miles';
        } else {
            this.units = 'kilometers';
        }
    }

    forceLogin(isAdmin) {
        this.cookie( 'returnto', window.location.href );
        let encodedUrl = encodeURIComponent( window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' ) + '/account/auth' );
        let url = window.muuv.memberauth + '?redirect=' + encodedUrl;
        if ( isAdmin ) {
            url = window.muuv.authorize + 'authorize?response_type=code&client_id=' + this.client_id + '&redirect_uri=' + encodedUrl;
        }
        return window.location.href = url;
    }

    clearReturn() {
        cookie.remove( 'returnto', { path: '/' });
    }

    cookie(key, value) {
        if ( !key ) return '';
        if ( !value ) return cookie.load( key );
        let expiresAt = new Date( new Date().getTime() + ( 60 * 60 * 1000 ) );
        cookie.save( key, value, { path: '/', expires: expiresAt });
    }

    isLogged() {
        return this.me.id ? true : false;
    }

    isAdmin() {
        if ( !this.me.roles ) return false;
        if ( this.me.roles.indexOf('admin') > -1 ) return true;
        if ( this.me.roles.indexOf('company_admin') > -1 ) return true;
        if ( this.me.roles.indexOf('club_admin') > -1 ) return true;
        return false;
    }

    isCompanyAdmin() {
        if ( !this.me.roles ) return false;
        if ( this.me.roles.indexOf('admin') > -1 ) return true;
        if ( this.me.roles.indexOf('company_admin') > -1 ) return true;
        return false;
    }

    canAdminClub( club_ident ) {
        if ( !this.me.roles ) return false;
        if ( this.me.roles.indexOf('admin') > -1 ) return true;
        if ( this.me.roles.indexOf('company_admin') > -1 ) return true;
        if ( this.me.roles.indexOf('club_admin') === -1 ) return false;
        if ( this.me.clubs.indexOf( club_ident ) > -1 ) return true;
        return false;
    }

    isTimeSlotValid(timeSlot) {
        let now = new Date().getTime();
        // Ignore slots in the past.
        let workoutEndTimeMinusTenMinutes = timeSlot.start_int + ( ( timeSlot.workout_minutes - 10 ) * 60 * 1000 );
        if ( now > workoutEndTimeMinusTenMinutes ) return false;
        // If this time is in the future, and there aren't any advance spots left
        // If this time slot is current
        if ( now > timeSlot.start_int ) {
            // See if there are open advance or walkin spots
            if ( timeSlot.advance_spots_open < 1 && timeSlot.walkin_spots_open < 1 ) return false;
        } else {
            // See if there are open advance spots
            if ( timeSlot.advance_spots_open < 1 ) return false;
        }
        return true;
    }

    verify() {
        if ( window.location.search.indexOf('?') === -1 ) {
            return this.exit();
        }
        // See if we have either the code or the token
        let codeParam = window.muuv.param('code');
        let tokenParam = window.muuv.param('token');
        if (codeParam) {
            Agent.Users.postVerify({
                code: codeParam,
                redirect_uri: window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' ) + '/account/auth'
            }).then((json) => {
                this.handleVerifyResponse(json);
            });
        } else if ( tokenParam ) {
            let tokenObj = null;
            try {
                tokenObj = JSON.parse(decodeURIComponent( tokenParam ));
            } catch (err) {
                console.debug(err);
            }
            if ( !tokenObj ) return this.exit();
            Agent.Users.postToken(tokenObj).then((json) => {
                this.handleVerifyResponse(json);
            });
        } else {
            return this.exit();
        }
    }

    exit() {
        window.location.replace( '/clubs' );
    }

    logout() {
        document.cookie = 'session=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = 'session.sig=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        Agent.Users.logout(window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' ));
    }

    handleVerifyResponse(json) {
        if ( json && json.error ) {
            alert( json.error );
        } else if (json && json.id) {
            this.me = json;
            // Use query string first, if it exists.
            let returnTo = window.muuv.param('return');
            if ( !returnTo ) returnTo = this.cookie('returnto');
            if ( returnTo ) {
                this.clearReturn();
                window.location.replace(returnTo);
            } else {
                this.exit();
            }
        } else {
            alert('Sorry, there was an error logging you in.');
        }
    }
}

decorate(UserStore, {
    lang: observable,
    units: observable,
    setLanguage: action
});
export default new UserStore();
