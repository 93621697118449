import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import './Information.scss';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { observer, inject } from "mobx-react";
import i18n from "i18next";

class ReservationsInformation extends Component {

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    componentDidMount() {
        const { t } = this.props;
        document.title = t('home.document_title');
        const us = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( us('home.document_title') );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: us('home.document_title'),
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        this.props.ModalStore.closeModal();
    }

    render() {
        const { t } = this.props;

        return(
            <Grid container justify='center'>
                <Grid container className='image-container'>
                    <div>
                        { t('home.hero.title') }
                    </div>
                </Grid>
                <Grid item xs={11} sm={9} md={7} lg={5} className='how-it-works'>
                    <Grid>
                        <Grid item xs={10} sm={8} className='button-container'>
                            <Link style={{textDecoration: 'none'}} to='/reservations'><Button className='manage-button'>{ t('home.cta.manage') }</Button></Link>
                        </Grid>
                        <Grid item xs={11} sm={8} className='button-container'>
                            <Link style={{textDecoration: 'none'}} to='/clubs'> <Button className='start-button'>{ t('home.cta.start') }</Button></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

ReservationsInformation = inject('ModalStore', 'UserStore')(observer(ReservationsInformation));
export default withTranslation()( ReservationsInformation );
