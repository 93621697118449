import React, { Component } from "react";
import { Link } from "react-router-dom";
import './ClubReservations.scss';
import ReservationsHeaderImage from '../../Images/Reservations/header.svg';
import { withTranslation, Trans } from 'react-i18next';
import DateUtils from "../../Utils/DateUtils";
import { inject, observer } from "mobx-react";
import i18n from "i18next";

class Confirmation extends Component {

    constructor(props) {
        super(props);
        this.props.ModalStore.openModal();
    }

    state = {
        loading: true,
        club: null,
        reservation: null
    };

    async componentDidMount() {
        const { t } = this.props;
        const { reservation_id } = this.props.match.params;
        let reservation = await window.muuv.get( '/reservations/' + reservation_id );
        if ( reservation.error ) return alert( reservation.error );
        let club = await window.muuv.get( '/clubs/' + reservation.club_ident );
        if ( club.error ) return alert( club.error );
        club.full_address = window.muuv.getFullAddress( club );
        this.setState({
            loading: false,
            club: club,
            reservation: reservation
        });
        document.title = t('club_reservations.confirmed.document_title');
        const en = i18n.getFixedT('en');
        window.muuv.analytics.setCurrentScreen( en('club_reservations.confirmed.document_title') );
        window.muuv.analytics.logEvent( 'page_view', {
            page_title: en('club_reservations.confirmed.document_title'),
            page_path: window.location.pathname,
            page_content: window.location.search,
            page_language: this.props.UserStore.lang,
            page_location: window.location.href
        } );
        this.props.ModalStore.closeModal();
    }

    goToMainScreen() {
        this.props.history.push('/');
    }

    render() {
        const { t } = this.props;
        let self = this;
        if ( self.state.loading ) return '';

        return (
            <div className="res res-final">
                <div className="res-header">
                    <img alt="Anytime Fitness" src={ ReservationsHeaderImage } onClick={this.goToMainScreen.bind(this)}  />
                </div>

                <div className="res-final-success">
                    <div className="res-final-confirmed">{ t('club_reservations.confirmed.title') }</div>
                    <span className="triangle"></span>
                </div>

                <div className="res-final-message">
                    { t('club_reservations.confirmed.sent_email') }
                </div>

                <div className="res-final-datetimebox">
                    <p>{ DateUtils.displayDateNoYearFromInt( self.state.reservation.start_int, self.state.club.timezone ) }</p>
                    <p>{ DateUtils.displayTimeFromInt( self.state.reservation.start_int, self.state.club.timezone ) } &ndash; { DateUtils.displayTimeFromInt( self.state.reservation.stop_int, self.state.club.timezone ) }</p>
                </div>

                <div className="res-final-datetimebox">
                    <a href={ self.state.club.url }>
                        <span className="res-club-name">{ self.state.club.name }</span>
                        <span className="res-address">{ self.state.club.full_address }</span>
                    </a>
                </div>

                <div className="res-final-message">
                    <h3>{ t('club_reservations.confirmed.about_visit') }</h3>
                    <ul>
                        <li>{ t('club_reservations.confirmed.sick_or_cannot_make_it_cancel') }</li>
                        <li>
                            <Trans i18nKey="club_reservations.confirmed.see_faq">
                                For additional questions see our <a href="https://www.anytimefitness.com/how-reservations-work/" target="_blank" rel="noopener noreferrer">FAQ</a>
                            </Trans>
                        </li>
                    </ul>
                </div>

                <Link className="res-final-selectnew" to="/reservations">{ t('club_reservations.confirmed.finish') }</Link>

            </div>
        );
    }
}

Confirmation = inject('ModalStore', 'UserStore')(observer(Confirmation));
export default withTranslation()( Confirmation );
