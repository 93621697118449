import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

let api = 'https://api.muuvlabs.com/anytime';
let host = window.location.hostname;
// Local
if ( host.indexOf('anytime-reservations.muuvlabs.local') > -1 ) {
    api = '//api.muuvlabs.local/anytime';
// Dev
} else if ( host.indexOf('localhost') > -1 || host.indexOf('anytime-reservations-dev.muuvlabs.com') > -1 ) {
    api = 'https://api-dev.muuvlabs.com/anytime';
// Staging
} else if ( host.indexOf('anytime-reservations-staging.muuvlabs.com') > -1 ) {
    api = 'https://api-staging.muuvlabs.com/anytime';
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: "en", // use en if detected lng is not available
        saveMissing: true, // send not translated keys to endpoint
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            skipOnVariables: false,
        },
        backend: {
            loadPath: api + '/translations/reservations/{{ lng }}',
            addPath: api + '/translations/reservations/{{ lng }}',
            crossDomain: true
        }
    });

export default i18n;
