import React, { Component } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Header from '../Header/Header';
import ReservationsInformation from '../Reservations/Information';
import Reservations from '../Reservations/Reservations';
import AccountAuth from '../Account/AccountAuth';
import AdminClubIndex from '../Admin/AdminClubIndex';
import AdminClubReservations from '../Admin/AdminClubReservations';
import AdminClubTimeSlots from '../Admin/AdminClubTimeSlots';
import ClubIndex from '../Reservations/ClubIndex';
import ClubReservations from '../Reservations/ClubReservations';
import Confirmation from '../Reservations/Confirmation';
import Modal from '../Modal/Modal';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import Maintenance from '../Maintenance/Maintenance';
import getMaintenanceApi from '../Maintenance/MaintenanceApi';

require('es6-promise').polyfill(); require('isomorphic-fetch');

class App extends Component {
    state = {}
    constructor() {
        super();
        this.checkStatus();
        setInterval(this.checkStatus.bind(this), 60000);
    }

    checkStatus(api) {
        fetch(getMaintenanceApi()).then((response) => {
            if (response.status === 200) {
                return response.json().then((resp) => {
                    if (resp === 1) {
                        this.setState({
                            underMaintenance: true
                        });
                    } else {
                        this.setState({
                            underMaintenance: false
                        });
                    }
                })
            } else {
                this.setState({
                    underMaintenance: false
                });
            }
        }).catch((err) => {
            this.setState({
                underMaintenance: false
            });
        });
    }

    render() {
        if (this.state.underMaintenance) {
            return (
                <>
                    <LanguagePicker></LanguagePicker>
                    <Maintenance></Maintenance>
                </>
            );
        } else {
            return(
                <div className='app'>
                    <LanguagePicker></LanguagePicker>
                    <Header></Header>
                    <Modal></Modal>
                    <Switch >
                        <Route path="/account/auth" exact component={ AccountAuth } />
                        <Route path="/admin" exact component={ AdminClubIndex } />
                        <Route path="/admin/clubs" exact component={ AdminClubIndex } />
                        <Route path="/admin/clubs/:club_ident" exact component={ AdminClubReservations } />
                        <Route path="/admin/clubs/:club_ident/time_slots" exact component={ AdminClubTimeSlots } />
                        <Route path="/clubs" exact component={ClubIndex}></Route>
                        <Route path="/clubs/:club_ident" exact component={ClubReservations}></Route>
                        <Route path="/reservations/:reservation_id" exact component={ Confirmation }></Route>
                        <Route path='/reservations' component={Reservations}></Route>
                        <Route path='' component={ReservationsInformation}></Route>
                    </Switch>
                </div>
            );
        }
    }
}

export default App;
