import { decorate, observable, action } from "mobx";
import ClubFinderStore from "../ClubFinderStore";
import UserStore from "../UserStore";
import Agent from "../../Api/Agent";
import {point, distance} from '@turf/turf';
import ModalStore from "../ModalStore";

class ClubListingsStore {

    inProgress = false;
    clubs = [];
    clubsInBoundaries = [];
    highlightedClubId = '';
    selectedClub;
    preventRefresh = false;
    isLoadingClubs = false;

    getClubById(id) {
        return this.clubs.find((club) => {
            return club.id === id;
        });
    }

    selectSelectedClub(club) {
        this.selectedClub = club;
        this.highlightedClubId = club ? club.id : '';
        if (club) {
            this.preventRefresh = true;
            ClubFinderStore.centerMap(club.lng, club.lat);
        }
    }

    setClubsOnMapBoundaries(map, preventRefresh) {
        if (this.preventRefresh || preventRefresh || this.isLoadingClubs) {
            this.preventRefresh = false;
            this.setClubsOnMap(this.clubsInBoundaries, map);
            return;
        }
        ModalStore.openInComponent();
        this.isLoadingClubs = true;
        const swlat = map.getBounds()._sw.lat, swlng = map.getBounds()._sw.lng, nelat = map.getBounds()._ne.lat, nelng = map.getBounds()._ne.lng;
        return Agent.ClubLocations.locationsInBoundaries(nelat, swlng, swlat, nelng).then((data) => {
            this.clubsInBoundaries = data.results.filter( function( club ) {
                return !club.should_be_excluded_from_maps;
            });
            this.sortClubsOnLocation(UserStore.units);
            this.setClubsOnMap(this.clubsInBoundaries, map);
            this.isLoadingClubs = false;
            ModalStore.closeInComponent();
            if (swlat !== map.getBounds()._sw.lat && swlng !==  map.getBounds()._sw.lng && nelat !== map.getBounds()._ne.lat && nelng !== map.getBounds()._ne.lng) {
                this.setClubsOnMapBoundaries(map);
            }
        });
    }

    setClubsOnMap(clubs, map) {
        let data = {
            type: 'FeatureCollection',
            features: []
        }
        clubs.forEach((club) => {
            if (club.id !== this.highlightedClubId) {
                data.features.push(
                    {
                        'type': 'Feature',
                        'properties': {id: club.id},
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [
                                club.lng,
                                club.lat
                            ]
                        }
                    }
                );
            } else {
                this.setSelectedClubOnMap(club, map);
            }

        });
        map.getSource('points').setData(data);
    }

    setSelectedClubOnMap(club, map) {
        let data = {
            type: 'FeatureCollection',
            features: []
        }
        data.features.push(
            {
                'type': 'Feature',
                'properties': {id: club.id},
                'geometry': {
                    'type': 'Point',
                    'coordinates': [
                        club.lng,
                        club.lat
                    ]
                }
            }
        );
        map.getSource('selected-point').setData(data);
    }

    /**
     * Calculate distances and sort clubs
     * @param {'kilometer, meter, miles'} units unit of measure
     */
    sortClubsOnLocation(units) {
        const actualPosition = point([ClubFinderStore.mapLocation.lon, ClubFinderStore.mapLocation.lat]);
        const options = {units: units};
        const mapLocation = ClubFinderStore.mapLocation
        if ( !this.clubsInBoundaries ) return;
        if (this.clubsInBoundaries.length > 0 && mapLocation.lat){
            this.clubsInBoundaries.forEach((club) => {
                if (!club.distance) {
                    const clubPoint = point([club.lng, club.lat]);
                    const distanceToClub = distance(actualPosition, clubPoint, options);
                    club.distance = distanceToClub.toFixed(1);
                }
            });

        }
        this.clubsInBoundaries = this.clubsInBoundaries.slice().sort( (a,b) => {
            return a.distance - b.distance
        })
    }

    /**
     * Calculate the distance with the given unit
     * @param {'kilometer, meter, miles'} units unit of measure
     */
    changeDistanceUnits(units) {
        const actualPosition = point([ClubFinderStore.mapLocation.lon, ClubFinderStore.mapLocation.lat]);
        const options = {units: units};
        if (this.clubsInBoundaries.length > 0) {
            this.clubsInBoundaries.forEach((club) => {
                const clubPoint = point([club.lng, club.lat]);
                const distanceToClub = distance(actualPosition, clubPoint, options);
                club.distance = distanceToClub.toFixed(1);
            });
        }
    }
}

decorate(ClubListingsStore,{
    inProgress: observable,
    clubsInBoundaries: observable,
    selectedClubId: observable,
    highlightedClubId: observable,
    getClubById: action,
    setClubsOnMapBoundaries: action
});

export default new ClubListingsStore();
