import { observable, action, decorate } from "mobx";
import UserStore from '../UserStore';

class ClubFinderStore {

    MAPBOX_TOKEN ='pk.eyJ1IjoidmlsYWJzIiwiYSI6ImNsZms0eWZ1NzA3aGozdHF4aGNqdzFpd24ifQ.h_6L1M7HMPN3FjMp2UEtMQ';
    mapRef;
    inProgress = false;
    showFailure = false;

    mapLocation = {
        lat: 44.94665,
        lon: -92.96299,
        name: 'Woodbury'
    }
    popupInfo = ''
    viewport = {
        latitude: 44.94665,
        longitude: -92.96299,
        zoom: 11
    }

    locationUpdated(result) {
        if (result.geometry) {
            this.mapLocation.lon = result.geometry.coordinates[0];
            this.mapLocation.lat = result.geometry.coordinates[1];
            this.mapLocation.name = result.text;
        }
    }

    findCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(this.getLocationByLatLon, this.geoError);
    }

    geoSuccess = (position) => {
        const lon = position.coords.longitude;
        const lat = position.coords.latitude;
        const new_viewport = {
            latitude: lat,
            longitude: lon
        }
        this.viewport = { ...this.viewport, ...new_viewport };

        if (this.mapRef) {
            this.mapRef.jumpTo({ center: [lon, lat] });
        }

        const result = {};
        result.geometry = {};
        result.geometry.coordinates = [];
        result.geometry.coordinates[0] = lon;
        result.geometry.coordinates[1] = lat;
        result.geometry.text = '';
        result.text = position.text ? position.text : '';
        this.locationUpdated(result);

    }

    geoError = async (error) => {
        this.showFailure = true;
        try {
            if ( !UserStore.isLogged() ) {
                const whereami = await window.muuv.get( '/users/whereami' );
                if ( whereami?.location?.latitude ) {
                    const position = {
                        coords: {
                            longitude: whereami?.location?.longitude,
                            latitude: whereami?.location?.latitude,
                        },
                        text: `${whereami.city?.names?.en}, ${whereami.subdivisions?.[0]?.iso_code}}`
                    };
                    this.geoSuccess(position);
                    return;
                }
           } else {
               const reservations = await window.muuv.get(
                   '/reservations?per_page=1'
               );
               if ( reservations.total > 0 ) {
                   const clubIdent = reservations.results[0].club_ident;
                   const club = await window.muuv.get( '/clubs/' + clubIdent );
                   if ( club?.lat ) {
                       const position = {
                           coords: {
                               longitude: club.lng,
                               latitude: club.lat
                           },
                           text: club.name
                       }
                       this.geoSuccess(position);
                       return;
                   }
               }
               if ( UserStore.me.home_club_ident ) {
                   const clubIdent = UserStore.me.home_club_ident;
                   const club = await window.muuv.get( '/clubs/' + clubIdent );
                   if ( club?.lat ) {
                       const position = {
                           coords: {
                               longitude: club.lng,
                               latitude: club.lat
                           },
                           text: club.name
                       }
                       this.geoSuccess(position);
                       return;
                   }
                }
            }
        } catch ( err ) {
            console.error( err.message );
        }
    }

    finishShowingError = () => {
        this.showFailure = false;
    }

    getLocationByZipCode = (zipcode) => {
        let filter = 'country=us&';
        this.getLocationFromApi(zipcode, filter);
    }

    getLocationByLatLon = (position) => {
        const lon = position.coords.longitude;
        const lat = position.coords.latitude;
        const param = lon + ', '.toString(16) + lat;
        this.getLocationFromApi(param);
    }

    centerMap(longitude, latitude) {
        if (this.mapRef) {
            this.mapRef.flyTo({ center: [longitude, latitude] });
        }
    }

    getLocationFromApi = (param, filters) => {
        const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + param + '.json?' + (filters ? filters : '') + 'access_token=' + this.MAPBOX_TOKEN;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                // If the request has no results then keep the current position
                if (data.features.length > 0) {
                    const location = data.features[0];
                    const position = {
                        coords: {
                            longitude: location.geometry.coordinates[0],
                            latitude: location.geometry.coordinates[1]
                        },
                        text: location.context[0].text
                    }
                    this.geoSuccess(position);
                } else {
                    window.alert('Location services failed');
                }
            });
    }

    setMapLanguage() {
        if (this.mapRef) {
            this.mapRef.setLayoutProperty('country-label', 'text-field', [
                'get',
                'name_' + UserStore.lang.split('-')[0]
                ]);
        }
    }
}

decorate(ClubFinderStore, {
    inProgress: observable,
    mapLocation: observable,
    showFailure: observable,
    popupInfo: observable,
    mapRef: observable,
    locationUpdated: action,
    findCurrentLocation: action
});

export default new ClubFinderStore();
